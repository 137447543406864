import React, { useState, useEffect } from "react";
// import config from "../../../coreFiles/config";
// import { Link } from "react-router-dom";
import "../../componentCss/header.css";
import config from "../../../coreFiles/config";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
  Offcanvas,
  Modal,
} from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Translator from "../Translator";
import Connectwallet from "../../ConnectWallet/index";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { polygon, polygonMumbai, bscTestnet } from "viem/chains";
import Web3ModalProvider from "../../ConnectWallet/Web3ModalProvider";
import { getAddressValidation } from "../../Web3/Connector";
import { registerUser, getAdminAddress } from "../../utils/apiServices";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as ACTIONTYPES from "../../redux/actionTypes";
import { Link } from 'react-router-dom';
const Header = () => {
  let { referraladdress } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);

  const { isConnected, address } = useAccount();
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();

  const [showModal, setShowModal] = useState(false);
  const [referralAddress, setReferralAddress] = useState("");
  const [referralAddressErrorMessage, setReferralAddressErrorMessage] =
    useState("");
  const _useWeb3ModalProvider = Web3ModalProvider();

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const setShow = async () => {
    open();
  };

  const handleChange = (value) => {
    setReferralAddressErrorMessage("");
    setReferralAddress(value);
  };

  const signUp = async (e) => {
    e.preventDefault();
    try {
      let referralAddessss
      let msg = "AUTOMATED SPACE LOOP PLACEMENT"

      if (msg == referralAddress) {

        let result = await getAdminAddress();
        if (!result.data.success) {
          return
        }
        let adminAddress = result.data.data
        referralAddessss = adminAddress
      } else {
        referralAddessss = referralAddress
      }

      let validation = await validateReferralAddress(referralAddessss);

      if (!validation) {
        return;
      }



      let payload = {
        address: address,
        referredBy: referralAddress,
      };

      let result = await registerUser(payload);
      if (result.data.success) {
        toast.success(result.data.message);
        dispatch({
          type: ACTIONTYPES.USERLOGIN,
          payload: result.data.data,
          token: result.data.token,
        });

        let path = `${config.baseUrl}dashboard`;
        setTimeout(() => {
          navigate(path);
        }, 3000);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateReferralAddress = async (refAdd) => {
    try {
      let isAddress = await getAddressValidation(
        refAdd,
        _useWeb3ModalProvider
      );
      let referralAddressError = "";
      if (refAdd == "") {
        referralAddressError = "Referral address required.";
      } else if (!isAddress) {
        referralAddressError = "Referral address is not valid web3 address.";
      } else if (refAdd == address) {
        referralAddressError =
          "Referral address and register addressis can not be same.";
      }

      if (referralAddressError == "") {
        setReferralAddressErrorMessage("");

        return true;
      }

      setReferralAddressErrorMessage(referralAddressError);
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDefaultChain(bscTestnet);
  }, []);

  useEffect(() => {
    if (address == undefined) {
      dispatch({
        type: ACTIONTYPES.USERLOGOUT,
        payload: [],
        token: [],
      });
    }
    callLoginApiMethod();
  }, [isConnected]);

  const callLoginApiMethod = async () => {
    try {
      if (!address) {
        return;
      }

      if (loginDetails) {
        return;
      }

      let payload = {
        address: address,
        referredBy: "",
      };

      let result = await registerUser(payload);
      if (result.data.success) {
        if (result.data.data.length > 0) {
          toast.success(result.data.message);

          dispatch({
            type: ACTIONTYPES.USERLOGIN,
            payload: result.data.data,
            token: result.data.token,
          });

          let path = `${config.baseUrl}dashboard`;
          setTimeout(() => {
            navigate(path);
          }, 3000);
          // login
        } else {
          if (referraladdress) {
            let payload = {
              address: address,
              referredBy: referraladdress,
            };

            let result = await registerUser(payload);
            if (result.data.success) {
              toast.success(result.data.message);
              dispatch({
                type: ACTIONTYPES.USERLOGIN,
                payload: result.data.data,
                token: result.data.token,
              });
              let path = `${config.baseUrl}dashboard`;
              setTimeout(() => {
                navigate(path);
              }, 3000);
            } else {
              toast.error(result.data.message);
            }
          } else {
            toast.success(result.data.message);

            handleShow();
          }
        }
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminAddresses = async (e) => {
    e.preventDefault()
    try {
      let msg = "AUTOMATED SPACE LOOP PLACEMENT"
      setReferralAddress(msg)
    } catch (error) {
      console.log(error)
    }
  }

  const Login = () => {
    let path = `${config.baseUrl}login`;
    navigate(path);
  };
  return (
    <>
      {/*------------------------- Header Section Start -------------------- */}
      <Toaster />

      <header className="">
        {["xl"].map((expand) => (
          <Navbar key={expand} expand={expand} className="mb-3 fixed-top">
            <Container>

              <Navbar.Brand>
                <Link to={`${config.baseUrl}`}> <img src="assets/images/logo.png" width="220px" alt="Logo" /> </Link>
              </Navbar.Brand>

              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>

                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <Link to={`${config.baseUrl}`}><img src="assets/images/logo.png" width="220px" /></Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                  <Nav className="justify-content-center flex-grow-1 pe-3">
                    <Nav.Link href={`${config.baseUrl}about`}>About</Nav.Link>
                    <Nav.Link href={`${config.baseUrl}feature`}>
                      Features
                    </Nav.Link>
                    <Nav.Link href={`${config.baseUrl}spacewall`}>
                      Space wall
                    </Nav.Link>
                    <Nav.Link href={`${config.baseUrl}faq`}>FAQs</Nav.Link>
                    {loginDetails && (
                      <>
                        <Nav.Link href={`${config.baseUrl}dashboard`}>
                          Dashboard
                        </Nav.Link>
                      </>
                    )}

                  </Nav>
                  <Form className="d-lg-flex  btn-header align-items-center">
                    <div className="me-3 ">
                      <Translator className="" />
                    </div>
                    <Button
                      variant="default"
                      className="px-4 me-2 bg-white text-black"
                      onClick={() => setShow()}
                    >
                      {isConnected
                        ? address.toString().substring(0, 4) +
                        "...." +
                        address
                          .toString()
                          .substring(address.length - 4, address.length)
                        : "Connect Wallet"}
                    </Button>

                    {/* <Button variant="primary" className="px-4">
                      Language
                    </Button> */}


                  </Form>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}

        <Modal show={showModal} onHide={handleClose} centered size="md">
          <Modal.Header
            className="border-0 border-bottom border-dark"
            closeButton
          >
            <Modal.Title>
              <div className="text-center">
                <h5 className="mb-0">Registration</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-5">
            <div className="px-4">
              <p className="mb-2">Please enter referral address (Upline Invite Link)</p>
              <div className="">
                <Form.Control
                  type="text"
                  style={
                    referralAddressErrorMessage != ""
                      ? { borderColor: "red" }
                      : {}
                  }
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                  value={referralAddress}
                ></Form.Control>
              </div>
              {referralAddressErrorMessage != "" && (
                <span style={{ color: "red" }}>
                  {referralAddressErrorMessage}
                </span>
              )}
            </div>


            <div className="text-center">
              <Button
                variant="primary"
                className="my-3 px-4"
                onClick={(e) => {
                  signUp(e);
                }}
              >
                Sign Up
              </Button>
            </div>
            <p className="mb-2 px-4">Don't have referral address or link ? <a style={{ color: "gold" }} onClick={e => getAdminAddresses(e)}>Click here</a></p>
          </Modal.Body>
        </Modal>
      </header>

      {/*------------------------- Header Section Exit -------------------- */}
    </>
  );
};

export default Header;
