import React from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import "../componentCss/faq.css"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Accordion
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from 'react-reveal';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay } from "react-icons/fa";



const Faq = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: "60px",
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Header />
            <div className="subheader" style={{ background: "url(assets/images/faq/faq.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >
                <div className="mt-5 d-flex justify-content-center align-items-center" style={{ height: "500px" }} >
                    <h2 className=" text-uppercase fw-medium">
                        <span className="text-primary">FAQ</span>
                    </h2>
                </div>

            </div>



            <div className="bg-galaxy py-lg-5" style={{ backgroundPosition: "bottom" }}>

                <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8} md={8} className="mb-4">
                                <Fade top>
                                    <div className="faq-accordion">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>HOW TO INSTALL PRIVATE WALLET</Accordion.Header>
                                                <Accordion.Body>

                                                    <p>You need to install any personal private wallet that can support walletconnect and which is compatible to your operating system.</p>
                                                    <p>We are supported by over 100 types of wallets like Metamask, Trust wallet, Coinbase wallet, Abra wallet, or Uniswap wallet, for more info : <a className="text-primary" href="https://walletconnect.com/explorer?type=wallet" target="_blank">https://walletconnect.com/explorer?type=wallet</a></p>


                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>HOW TO ENSURE THE SAFETY OF MY PRIVATE WALLET</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Write down and store your 12 words seed phrase for your wallet account and make sure nobody has access to it except your family members. </p>
                                                    <p>The failure to do so could result in the potential loss of funds. </p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>HOW TO SIGNUP</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Load the wallet with space 1 activation USD 50 worth of polygon matic with some gas fee. <br />
                                                        Move to the discover / browser section in the app.<br />
                                                        Paste your upline’s Hi5 Space invite link in the  browser.<br />
                                                        Login and activate with starter package space 1 donation of USD 50.
                                                    </p>
                                                    <p>Open the wallet app on your device.</p>

                                                    <p>Find the WalletConnect feature in the wallet app's menu or settings.</p>

                                                    <p>Use the wallet app's QR code scanner to scan the QR code displayed by the dApp or input the connection details manually.</p>

                                                    <p>Review and authorize the connection request displayed on your wallet app.</p>

                                                    <p>Click "Approve" or "Confirm" to establish the connection.</p>

                                                    <p>Start using your connected wallet to interact with the dApp.</p>

                                                    <p>Manage connected wallets and disconnect from a dApp if needed.</p>

                                                    <p>Remember to be cautious and verify the authenticity of the dApps you interact with.</p>

                                                    <p>That's it! Now you can securely link your wallet using WalletConnect and enjoy decentralized applications.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>HOW TO GET POLYGON MATIC</Accordion.Header>
                                                <Accordion.Body>
                                                    Use the swap feature on our website and exchange your crypto to polygon matic or purchase polygon matic using debit/credit card.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>HOW TO ACCESS MY ACCOUNT</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>You can access your account in the page <a href="#" className="text-primary text-decoration-none">sign in.</a></p>
                                                    <p>Logging into your account is completely by using your personalized private  wallet address and with out a password.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>WHAT IS Hi5 SPACE</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Hi5 Space is an innovative and interactive community platform that serves as a hub for passionate social entrepreneurs worldwide. By utilizing Hi5 Space, both individuals and organizational members can effectively generate global funding to support and provide support to resources for various charitable initiatives. Hi5 Space is not just a mere platform, but rather an independent socio-economic movement that aims to inspire, motivate, educate, liberate, and elevate the lifestyles of its community members.</p>
                                                    <p>At its core, Hi5 Space is a global community of social entrepreneurs who are deeply committed to creating positive change in the world. They are driven by a desire to make a meaningful impact on individuals and communities in need. Together, they work faithfully to build a better future for all. What sets Hi5 Space apart is our unwavering commitment to the community. We firmly believe in promoting a global social entrepreneurship movement where 100% of the donations are distributed among our members. By supporting and coming together, we can create a better world for everyone.</p>
                                                    <p>The Hi5 Space Community is a revolutionary ecosystem that promotes unity in diversity and equality. It boasts a fully automated space loop protocol, which is powered by an intelligent algorithm system. This platform is built on polygon smart contract blockchain technology and utilizes polygon matic cryptocurrency. It offers 100% functionality for all users through P2P wallet interactions. This unique crowdfunding affiliate system is powered by Hi5 Space Loop Super Technology and operates globally as a fully integrated, automated fundraising platform on the polygon blockchain. It also includes features such as space auto-upgrades, space auto-re-entry, and space pay it forward system, all based on polygon's transaction timestamp and global community placement.</p>
                                                    <p>Join our social entrepreneurship crowdfunding movement where we support each other and create a win-win synergy. By making a one-time donation of USD 50 worth of polygon matic cryptocurrency, you automatically become a giver and a receiver in our Hi5 Space community. All donations are directly transferred to your personal wallet of choice. With our platform being compatible with over 100 wallets, you can choose any private wallet, making it simple for everyone to make a positive difference.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>WHAT IS SOCIAL ENTREPRENEUR</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Social entrepreneurs are individuals or organizations who strive to bring about positive change in society by creating innovative solutions to social problems, utilizing entrepreneurial principles to establish and maintain social enterprises that focus on addressing pressing issues, including poverty, inequality, environmental degradation, and access to healthcare and education. Their objective is to create sustainable and scalable solutions that tackle the root causes of social problems and benefit marginalized or underserved communities. Social entrepreneurs often collaborate with government agencies, private sector entities, and non-profit organizations to pool resources and amplify their impact. </p>
                                                    <p>Hi5 Space social entrepreneurs utilize blockchain technology, embrace sustainability, and harness the innovative Hi5 Space platform to effectively tackle social issues. They approach these challenges with creativity, fueled by a profound sense of purpose and a commitment to making a significant impact on both themselves and the global community. As a result, they can achieve extraordinary success in their charitable endeavors.</p>


                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header>WHO SHOULD JOIN Hi5 SPACE</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>This movement welcomes everyone, business people,working people including organizations, cooperatives, houses of worship, NGOs, and their network members who would like to increase funds to resources for their charity activities as well as individuals with social responsibility and who want to change their lifestyle dreams. It also offers support to those who aspire to become social entrepreneurs, fostering progress and addressing social challenges. Through this innovative platform, we offer a fresh approach to transforming society and providing a sustainable and equitable way to enrich everyone in the community. This Hi5 Space platform encourages reciprocal donation sharing and aims to enhance holistic development. Join us with teams of people driven by love, compassion, and generosity to strengthen social responsibility and uplift communities. Our primary focus is to enrich each other's lives and collaborate towards building a better world for everyone.</p>
                                                    <div className="heading">
                                                        <h5>Hi5 SPACE IS BORN TO CHANGE THE WORLD</h5>

                                                    </div>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="8">
                                                <Accordion.Header>WHAT IS SPACE TYPE</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Space type is the donation level in Hi5 Space Community   </p>
                                                    <p>We have 5 different spaces starting from USD 50</p>
                                                    <div className="table-responsive">
                                                        <table className="w-100 " >
                                                            <thead>
                                                                <tr>
                                                                    <th className="border border-dark pl-1">SPACE TYPE</th>
                                                                    <th className="border border-dark pl-1">DONATION AMOUNT</th>
                                                                    <th className="border border-dark pl-1">ACTIVATION</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="border border-dark pl-1">SPACE 1</td>
                                                                    <td className="border border-dark pl-1">USD 50</td>
                                                                    <td className="border border-dark pl-1">Start Donation</td>
                                                                </tr>
                                                                <tr className="">
                                                                    <td className="border border-dark pl-1">SPACE 2</td>
                                                                    <td className="border border-dark pl-1">USD 250</td>
                                                                    <td rowspan="4" className="w-50 border border-dark pl-1">System automatically will upgrade from the reserve fund in your personal escrow wallet</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border border-dark pl-1">SPACE 3</td>
                                                                    <td className="border border-dark pl-1">USD 1,250</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border border-dark pl-1">SPACE 4</td>
                                                                    <td className="border border-dark pl-1">USD 6,250</td>
                                                                    <td ></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border border-dark pl-1">SPACE 5</td>
                                                                    <td className="border border-dark pl-1">USD 31,250</td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="mt-3">
                                                        <h6 className="lh-base">With just a USD 50 out-of-pocket donation, you can activate Space 1 and become a lifelong active donor in Hi5 Space community. This secures a permanent, recurring, and dynamically positioned blockchain position within the Space 1 matrix. Furthermore, the intelligent Hi5 Space Loop will automatically upgrade all successive Hi5 Space positions (ranging from Space 2 to Space 5) using reserve funds from the escrow wallet.
                                                        </h6>
                                                    </div>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="9">
                                                <Accordion.Header>WHAT IS GLOBAL AUTOMATED POWERLINE PLACEMENT</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The placement of the matrix structure from space 2 to space 5 is not limited by upline or downline but it is determined by a unified global community network. Globally one line structure placement system whereby every space entry position will be placed under a previously joined matrix position according to polygon transaction timestamp by the Hi5 Space Loop. Once re-entry occurs, all previous positions in the matrix will seamlessly reintegrate into the space matrix. Uplines and downlines are not constrained by any specific arrangement in the matrix structure, granting them the freedom to navigate the Space Loop of the matrix and support one another in achieving their aspirations. This principle is reinforced by the practice of early joiners space position who have achieved success in receiving the matrix donations, they are entrusted with the auto social responsibility of assisting in filling up the space matrix positions for newcomers, irrespective of their invite network.</p>
                                                    <p>This means that even without inviting anyone, you still have the opportunity to receive multiple donations from the global community space matrix, unlimited times, across all your space positions. It operates on a global one-line structure system with recurrent auto dynamic space matrix positions, based on the timestamp of polygon transactions (first come, first served).</p>
                                                    <p>Timestamp is a time recorded by the polygon blockchain that gives the received time of the respective transaction of space donation payment.
                                                    </p>



                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="10">
                                                <Accordion.Header>SPACE MATRIX FILLUP PROCEDURE</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Introducing our revolutionary Hi5 Space Matrix, built upon a 5x3 matrix arrangement that encompasses 5 positions in level 1, 25 positions in level 2, and 125 positions in level 3, resulting in a grand total of 155 positions for each space cycle.</p>

                                                    <p>Our state-of-the-art Space Loop system offers lifetime auto dynamic space matrix blockchain positions, including multiple automatic re-entry positions, multiple automatic upgrade positions, and automatic pay-it-forward positions. Each recurrent auto dynamic rebirth space position is associated with a unique space ID number while sharing the same wallet address. By leveraging these repeated auto dynamic rebirth space matrix positions, you'll significantly enhance your prospects of receiving unlimited space donations.</p>

                                                    <p>The arrangement of space matrix structure with the multiple rebirth positions can be classified into two methods:
                                                    </p>
                                                    <div>
                                                        <p>Space 1		: Follow the personal invitation-based community network with grandparent rule </p>
                                                        <p>Space 2 to Space 5	: Follow the global community with Space Loop power line movement
                                                        </p>
                                                    </div>


                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="11">
                                                <Accordion.Header>WHAT IS Hi5 SPACE LOOP</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Space Loop is a unique architecture set of protocols driven by an intelligent algorithm system to ensure everyone can receive donations. This automated engineering system generates an innovation balance of wealth distribution within our eco-system.</p>

                                                    <p> <b>This super technology Space Loop</b> ensures a harmonious distribution of positions by facilitating recurrent automatic dynamic rebirth space matrix positions, automatic re-entry positions, and automatic space upgrade positions, thereby maintaining a consistent, symmetrical, and inclusive space matrix structure for all participants. </p>

                                                    <p>The interactions between various internal space networks by Space Loop have a significant impact on the acceleration of space matrix position movements.
                                                    </p>
                                                    <p>
                                                        The space matrix positions are efficiently filled in both forward and backward directions, creating a mirrored effect that maintains a balanced distribution of positions. Through the synchronized Space Loop auto-filling process within the space matrix, a harmonious distribution is achieved, ensuring a consistent and symmetrical structure that benefits all participants.
                                                    </p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="12">
                                                <Accordion.Header>WHAT IS SPACE DONATION SHARING AMOUNT</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>In our Hi5 Space community, 100% of the donations are fully distributed among the community through two sharing types: </p>
                                                    <div>
                                                        <ol className="pl-0">
                                                            <li>
                                                                Invite Donation (20%)
                                                            </li>
                                                            <li>
                                                                Community Donation (80%)
                                                            </li>
                                                        </ol>
                                                    </div>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="13">
                                                <Accordion.Header>WHAT IS ACTIVE DONOR</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>When a person registers as a free member on Hi5 Space and activates Space 1 donation with a one-time out-of-pocket payment of USD 50 and they are eligible to receive all types of Hi5 Space donations. Additionally, they are assigned a position in the Space Matrix and become eligible for rebirth space id with multiple auto-upgrade positions, multiple re-entry positions, and they can use the pay-it-forward system. By adopting this approach, active donors have the opportunity to receive multiple invite donations from their same direct personal contacts, as well receiving multiple community donations across the entire space network.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="14">
                                                <Accordion.Header>WHAT IS INACTIVE DONOR</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>When a person registers as a free member on Hi5 Space but has not yet activated their Space 1 donation, they are considered an inactive donor. Inactive donors are only eligible to receive invite donations. Despite being inactive, they can still invite their contacts using their personal invite link. Once their personal contacts become active donors, the inactive donor will receive unlimited 20% invite donations from all the rebirth of re-entry positions and upgrade positions. All their direct active donors will be arranged first in the Hi5 space matrix following the grandparent rule, and they will follow you in the next space 1 cycle if you have already become an active donor. </p>
                                                    <p>Inactive donor are not in any space matrix so they are not qualify for space matrix community donation. As an inactive donor, a period of 50 days is given from the time of registration, during which they can only qualify for receiving invite donations. To become an active donor, they must activate their Space 1 donation. If they fail to do so within the 50-day period, the smart contract will remove their ID and compress all their direct contacts upward based on the grandparent rule.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="15">
                                                <Accordion.Header>WHAT IS 20% INVITE DONATION</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>First you have to register your direct referral through your personal Hi5 Space invite link.
                                                        Every individual's personal private wallet has a unique invite link for Hi5 Space,
                                                        for example, <a href="https://hi5space.com/0xF26E623EEAFe62e6F1959d1a3a7EcF95c3A9Af1c" className="text-primary text-decoration-none fw-normal">HTTPS://HI5SPACE.COM/0xF26E623EEAFe62e6F1959d1a3a7EcF95c3A9Af1c</a>. </p>
                                                    <p>When your personal referral becomes an active donor, you'll receive a 20% donation as invite donation. The donation money goes directly to your private wallet in real-time. Additionally, you'll also receive a 20% invite donation for all the auto upgrade packages from Space 2 to Space 5. The more personal referrals you invite using your Hi5 Space invite link, the more donations you'll receive. You'll receive multiple of 20% invite donations from the same referral on all auto upgrade positions and auto-re-entry positions from Space 1 to Space 5.
                                                    </p>
                                                    <div>
                                                        <p>The table below shows the invite donation allocations for space matrix</p>
                                                        <div className="table-responsive">
                                                            <table width={`100%`}>
                                                                <tr>
                                                                    <td className="border pl-1">SPACE</td>
                                                                    <td className="border pl-1">Space 1</td>
                                                                    <td className="border pl-1">Space 2</td>
                                                                    <td className="border pl-1">Space 3</td>
                                                                    <td className="border pl-1">Space 4</td>
                                                                    <td className="border pl-1">Space 5</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="border pl-1">DONATION AMOUNT</th>
                                                                    <td className="border pl-1">USD 50</td>
                                                                    <td className="border pl-1">USD 250</td>
                                                                    <td className="border pl-1">USD 1,250</td>
                                                                    <td className="border pl-1">USD 6,250</td>
                                                                    <td className="border pl-1">USD 31,250</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="border pl-1">INVITE DONATION</th>
                                                                    <td className="border pl-1">USD 10</td>
                                                                    <td className="border pl-1">USD 50</td>
                                                                    <td className="border pl-1">USD 250</td>
                                                                    <td className="border pl-1">USD 1,250</td>
                                                                    <td className="border pl-1">USD 6,250</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="16">
                                                <Accordion.Header>WHAT ARE THE BENEFITS OF INVITING MORE DIRECT REFERRALS</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>There are great opportunities to receive more personal 20% invite donation for every rebirth positions.</p>
                                                    <div>
                                                        <ol className="pl-0">
                                                            <li>For every personal referral who becomes an active donor in space 1, you'll receive a 20% invite donation. </li>
                                                            <li>When Space Loop trigger auto upgrade from space 2 to space 5 you’ll receive multiple 20% as invite donation.</li>
                                                            <li>For every auto re-entry position (space 1 to space 5) you’ll receive 20% as invite donation multiple times.</li>
                                                            <li>When you activate someone by using pay it forward method you will receive 20% as invite donation. </li>
                                                            <li>When the pay it forward direct referrals upgrade or re-enter you will receive multiple times as 20% invite donation</li>
                                                            <li>
                                                                Community Donation (80%)
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <p>In reality, invite donations are much more powerful than community donations, which are like royalty revenue. You only need to invite your direct referrals into Space 1 donation once. Once their auto-re-entry wallet and auto-upgrade wallet have enough funds, the system will automatically generate multiple rebirth positions with the same wallet address but different IDs in the Space matrix. This movement will allow you to receive 20% invite donations, like royalty revenue, from the same referral multiple times. By inviting more people to this movement, we can make a difference for ourselves and the world.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="17">
                                                <Accordion.Header>WHAT IS 80% COMMUNITY DONATION</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The Community Donation Sharing system is based on a 5 by 3 matrix, with a total of 155 positions available in each of the five spaces.</p>
                                                    <p>Active donors will receive a position in the Hi.5 Space Intelligent Algorithm System, which will provide community donations on the 5 by 3 matrix up to three levels below their position, with a total of 155 donations (5 positions + 25 positions + 125 positions) for each space.</p>
                                                    <p>The space matrix positions will automatically fill up by Space Loop using the reserve money from your escrow wallet for spaces 1 to 5, allowing you to receive community donations for all your re-entry positions and upgrade positions. The same wallet address will undergo multiple rebirth space ID with auto reentry and auto upgrades into the space matrix, making everyone eligible to receive multiple community donations.
                                                    </p>

                                                    <h6>THE MATRIX DONATION FOR ALL THE SPACE PACKAGE (SPACE 1 TO SPACE 5) USES THE SAME FORMULA.</h6>
                                                    <div className="mb-4">
                                                        <table width={`100%`} className="text-center">
                                                            <tr>
                                                                <th className="border pl-1" colSpan={`3`}>SPACE MATRIX PLACEMENT DONATION SHARING</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                            <tr>
                                                                <th className="border pl-1">LEVEL</th>
                                                                <th className="border pl-1">MATRIX POSITION</th>
                                                                <th className="border pl-1">DONATION SHARING</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1">1</td>
                                                                <td className="border pl-1">5</td>
                                                                <td className="border pl-1">5%</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1">2</td>
                                                                <td className="border pl-1">25</td>
                                                                <td className="border pl-1">25%</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1">3</td>
                                                                <td className="border pl-1">125</td>
                                                                <td className="border pl-1">50%</td>
                                                            </tr>
                                                        </table>
                                                    </div >
                                                    <p className="mb-3">THE TABLE BELOW SHOWS THE SPACE MATRIX DONATION SHARING FOR THE SPACE MATRIX</p>

                                                    <div className="border text-center mb-4">
                                                        <h6 className="p-2 mb-0">MATRIX DONATION SHARING ON MATRIX PLACEMENT </h6>
                                                    </div>

                                                    <div className="mb-4">
                                                        <table width={`100%`} className="text-center">
                                                            <tr>
                                                                <th className="border pl-1">LEVEL</th>
                                                                <th className="border pl-1">SHARING</th>
                                                                <th className="border pl-1">SPACE 1</th>
                                                                <th className="border pl-1">SPACE 2</th>
                                                                <th className="border pl-1">SPACE 3</th>
                                                                <th className="border pl-1">SPACE 4</th>
                                                                <th className="border pl-1">SPACE 5</th>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1">1</td>
                                                                <td className="border pl-1">5 %</td>
                                                                <td className="border pl-1">$2.5</td>
                                                                <td className="border pl-1">$12.5</td>
                                                                <td className="border pl-1">$62.5</td>
                                                                <td className="border pl-1">$312.50</td>
                                                                <td className="border pl-1">$1,562.50</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1">2</td>
                                                                <td className="border pl-1">25 %</td>
                                                                <td className="border pl-1">$12.50</td>
                                                                <td className="border pl-1">$62.50</td>
                                                                <td className="border pl-1">$312.50</td>
                                                                <td className="border pl-1">$1562.50</td>
                                                                <td className="border pl-1">$7,812.50</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1">3</td>
                                                                <td className="border pl-1">50 %</td>
                                                                <td className="border pl-1">$25</td>
                                                                <td className="border pl-1">$125</td>
                                                                <td className="border pl-1">$625</td>
                                                                <td className="border pl-1">$3,125</td>
                                                                <td className="border pl-1">$ 15,625</td>
                                                            </tr>

                                                        </table>
                                                    </div >
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="18">
                                                <Accordion.Header>WHAT IS DIRECT COMMUNITY DONATION</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The space community donation, consisting of a 5 by 3 matrix (with 5 positions+25 positions+125 positions), is divided into two categories:</p>
                                                    <div>
                                                        <ol className="pl-0" type="a">
                                                            <li>DIRECT COMMUNITY DONATION – DONATIONS ARE DIRECTLY INTO THE PRIVATE WALLET OF YOUR CHOICE.</li>
                                                            <li>RECIPROCAL COMMUNITY DONATION – DONATIONS TO PERSONAL ESCROW WALLET FOR AUTO UPGRADING, AUTO RE-ENTRY AND PAY IT FORWARD.</li>
                                                        </ol>
                                                    </div>
                                                    <p>Out of the total 155 positions, 105 positions fall under the direct community donation category. The direct community donation for each position of the space matrix will be in your personal wallet.</p>
                                                    <div className="mb-4">
                                                        <table width={`100%`} className="text-center">
                                                            <tr>
                                                                <td className="border pl-1 py-1">MATRIX </td>
                                                                <td className="border pl-1 py-1">DIRECT COMMUNITY DONATION</td>
                                                                <td className="border pl-1 py-1">NUMBER OF DONATIONS</td>

                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL  1</td>
                                                                <td className="border pl-1 py-1">ALL 5 POSITIONS</td>
                                                                <td className="border pl-1 py-1">5  DONATIONS</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL  2</td>
                                                                <td className="border pl-1 py-1">POSITION (6 - 10) & (16 - 25)</td>
                                                                <td className="border pl-1 py-1">15  DONATIONS</td>

                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL  3</td>
                                                                <td className="border pl-1 py-1">POSITION (21 –30),(41-50) & (61-125)</td>
                                                                <td className="border pl-1 py-1">85  DONATIONS</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={`2`} className="border pl-1 py-1">TOTAL DIRECT COMMUNITY DONATION</td>

                                                                <td className="border pl-1 py-1">105  DONATIONS</td>
                                                            </tr>
                                                        </table>
                                                    </div >
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="19">
                                                <Accordion.Header>WHAT IS RECIPROCAL COMMUNITY DONATION</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Out of a total of 155 positions for each space matrix cycle, the Space Loop will automatically contribute 50 donations back into the space community matrix. These 50 positions will be dedicated to donations within the autopilot space builder, facilitated through re-entry, upgrades, and a pay-it-forward system. These reserve donations, contributed by all space matrix positions, will be held in a personal escrow wallet specifically for the purpose of the Hi5 Space Loop's auto space builder.</p>


                                                    <div className="mb-4">
                                                        <table width={`100%`} className="text-center">
                                                            <tr>
                                                                <td className="border pl-1 py-1">MATRIX </td>
                                                                <td className="border pl-1 py-1">RECIPROCAL COMMUNITY DONATION</td>
                                                                <td className="border pl-1 py-1">NUMBER OF DONATIONS</td>

                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL  1</td>
                                                                <td className="border pl-1 py-1">NONE</td>
                                                                <td className="border pl-1 py-1">-</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL  2</td>
                                                                <td className="border pl-1 py-1">POSITION 1 TO 5 AND POSITION 11 TO 15</td>
                                                                <td className="border pl-1 py-1">10 DONATIONS</td>

                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL  3</td>
                                                                <td className="border pl-1 py-1">POSITION 1 TO 20, POSITION 31 TO 40 & POSITION 51 TO 60</td>
                                                                <td className="border pl-1 py-1">40 DONATIONS</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={`2`} className="border pl-1 py-1">TOTAL RECIPROCAL COMMUNITY DONATION</td>

                                                                <td className="border pl-1 py-1">50 DONATIONS</td>
                                                            </tr>
                                                        </table>
                                                    </div >
                                                    <p>In essence, this means that for each position within the space matrix (Space 1 to Space 5), you contribute one donation and receive 155 donations in return. Subsequently, the Space Loop auto system will redistribute 50 donations back to the space community matrix. This movement has the potential to greatly benefit our entire community by helping everyone achieve financial freedom.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="20">
                                                <Accordion.Header>CAN I SIGNUP WITHOUT INVITER</Accordion.Header>
                                                <Accordion.Body>
                                                    <h6>YES, YOU CAN </h6>
                                                    <p>If you've received a personal invite link from someone, it is encouraged to register under their invite link on Hi5 Space. Doing so will provide you with the opportunity to engage and collaborate closely with a team. Hi5 Space is a global community movement dedicated to generating funds for charitable activities led by social entrepreneurs. The collaborative and mutually supportive nature of team participation ensures a smoother path towards building a strong social entrepreneur community. </p>

                                                    <p>However, if you sign up without a personal invite link, the smart contract will automatically redirect your Space ID to the Space 1 matrix position. Your placement spot will be determined by set protocols using the Hi5 Space Loop.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="21">
                                                <Accordion.Header>CAN I RECEIVE DONATIONS WITHOUT REFERRING OR INVITING ANYONE</Accordion.Header>
                                                <Accordion.Body>
                                                    <h6>YES, YOU ARE ELIGIBLE TO RECEIVE ONLY 80% OF THE COMMUNITY DONATION</h6>
                                                    <p>The system will automatically fill -up the space matrix using Hi5 Space Loop.
                                                        Overflows positions and the impact from auto re-entry wallet will give you a great accelerations on space position movement.
                                                    </p>
                                                    <p>For every position in Hi5 Space matrix you are eligible to receive 3 levels below on 5 by 3 matrix with a total 155 positions ( 5 positions + 25 positions + 125 positions ).
                                                    </p>
                                                    <div className="mb-4">
                                                        <table width={`100%`} className="text-center">
                                                            <tr>
                                                                <td className="border pl-1 py-1" colSpan={`3`}>SPACE MATRIX PLACEMENT DONATION SHARING </td>
                                                                <td className=""></td>
                                                                <td className=""></td>

                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">LEVEL</td>
                                                                <td className="border pl-1 py-1">MATRIX POSITION</td>
                                                                <td className="border pl-1 py-1">DONATION SHARING</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">1</td>
                                                                <td className="border pl-1 py-1">5</td>
                                                                <td className="border pl-1 py-1">5%</td>

                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">2</td>
                                                                <td className="border pl-1 py-1">25</td>
                                                                <td className="border pl-1 py-1">25%</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border pl-1 py-1">3</td>
                                                                <td className="border pl-1 py-1">125</td>
                                                                <td className="border pl-1 py-1">50%</td>
                                                            </tr>

                                                        </table>
                                                    </div >
                                                    <p>
                                                        You are entitled to receive 80% of the donations in the Space Matrix (Spaces 2 to 5) without needing to invite anyone. The system will automatically fill up the Space Matrix based on global automated powerline placement protocols using the Hi5 Space Loop. Once a matrix position movement occurs in your personal 5 by 3 Space Matrix, you will start receiving the matrix donations. Only the 20% invite donation is limited to individuals who have personal direct invitees.

                                                    </p>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="22">
                                                <Accordion.Header>CAN I UPGRADE TO SPACE 3 WITHOUT PARTICIPATING IN SPACE 1 AND SPACE 2 </Accordion.Header>
                                                <Accordion.Body>
                                                    <h6>NO, YOU CAN’T SKIP</h6>
                                                    <p>In order to become a member of Hi5 space, you need to start with Space 1 and follow the upgrade process in ascending order. Once your Space Upgrade Wallet accumulates enough funds, Space Loop will automatically upgrade you to the next level. This process will happen without requiring completion of your current space matrix. When sufficient funds are available in your upgrade wallet, the space upgrade process will take place. If you wish to expedite your upgrade progress, you have the option to manually add sufficient funds to your upgrade wallet, which will accelerate the movement of your space position within the matrix space.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="23">
                                                <Accordion.Header>WHAT IS GENERATIVE SPILLOVER </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Generative spillover offers a method to effortlessly obtain free referrals from individuals positioned above you (Spaces 1 to 5), whether they belong to your network community or a global community within the Hi5 Space matrix. Moreover, when each individual position contributes back 50 donations to the Hi5 Space community, it accelerates the progress within the space matrix positions and promotes equitable enrichment across all communities. This automated system operates through an algorithmic protocols (Hi5 Space Loop),  employing an automatic generative space position spillover feature that ensures continuous growth within the space matrix positions.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="24">
                                                <Accordion.Header>WHAT IS RE-ENTRY WALLET </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Your personal escrow wallets will receive a total of 30 reserve donations for the purpose of space auto re-entry. These donations will come from three groups of positions within each Level 3 space matrix (Space 1 to Space 5),: positions 11 to 20, positions 31 to 40, and positions 51 to 60. The 30 reserve donations from each space in the Level 3 matrix will be distributed among these groups according to a sharing formula, to facilitate re-entry into the space matrix.</p>
                                                    <div className="mb-4">
                                                        <table width={`100%`} className="text-center">
                                                            <tr>
                                                                <td className="border py-1" colSpan={`7`}>RE-ENTRY SHARING FORMULA FOR THE 30 RESERVE DONATIONS FROM LEVEL 3</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border py-1">
                                                                    LEVEL 3<br />
                                                                    SPACE MATRIX</td>
                                                                <td className="border py-1">SPACE 1<br />
                                                                    RE-ENTRY WALLET</td>
                                                                <td className="border py-1">SPACE 2<br />
                                                                    RE-ENTRY WALLET</td>
                                                                <td className="border py-1">SPACE 3<br />
                                                                    RE-ENTRY WALLET</td>
                                                                <td className="border py-1">SPACE 4<br />
                                                                    RE-ENTRY WALLET</td>
                                                                <td className="border py-1">SPACE 5<br />
                                                                    RE-ENTRY WALLET</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border py-1">SPACE 1</td>
                                                                <td className="border py-1">30</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border py-1">SPACE 2</td>
                                                                <td className="border py-1">10</td>
                                                                <td className="border py-1">20</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border py-1">SPACE 3</td>
                                                                <td className="border py-1">10</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">20</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border py-1">SPACE 4</td>
                                                                <td className="border py-1">10</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">20</td>
                                                                <td className="border py-1">-</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border py-1">SPACE 5</td>
                                                                <td className="border py-1">10</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">-</td>
                                                                <td className="border py-1">20</td>
                                                            </tr>
                                                        </table>

                                                    </div>
                                                    <p>The space re-entry reserve donations will be allocated to Space 1 first, and subsequently to the next space with a maximum of 20 reserved donations per space. Once there is sufficient funding in each space's re-entry wallet, the system will automatically trigger the next space position in the relevant space based on the Space Loop. Each space re-entry position will have the same wallet address but a different space cycle ID number. For example, for every USD50 in Space 1's re-entry wallet, the system will trigger one Space 1 position based on the Space Loop. This automatic arrangement method gives a great acceleration for multiple re-entry positions in the space matrix movement. With each re-entry position resulting from a sponsorship, the inviter will be entitled to receive 20% as the invite donation (space 1 to space 5).<br /><br /> This implies that from a single sponsorship, one can receive multiple invite donations for each automatic re-entry positions that takes place.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="25">
                                                <Accordion.Header>WHAT IS UPGRADE WALLET </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Your personal escrow wallet will receive ten reserve donations for the space auto upgrade. For each space (Space 1 to Space 5), the first ten positions of the Level 3 matrix donations will be reserved for the space auto upgrade wallet, with the exception of Space 5. Once your upgrade reserve wallet has sufficient funds, the space upgrade will automatically trigger with the new positions. As more funds accumulate in your upgrade wallet, additional auto upgrade positions will occur in the same space. Each upgrade position has the same wallet address but a unique ID number, and the space upgrade can occur without completing the current full space matrix structure. This method will greatly accelerate the movement of space matrix positions for everyone in our Hi5 Space community. Similar to the re-entry wallet, the sponsor will experience the impact of receiving multiple invite donations from each upgraded positions (space 1 to space 5).</p>
                                                    <p>There is no next upgrade space available at Space 5, and therefore, the 10 reserve positions of Level 3 Space 5 donations will be automatically redirected to the re-entry wallet as follows:</p>
                                                    <div>
                                                        <ul>
                                                            <li>The first 5 position donations will be redirected to the Space 2 re-entry wallet.</li>
                                                            <li>The remaining 5 position donations (from 6th position to 10th position) will be redirected to the Space 3 re-entry wallet.</li>
                                                        </ul>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="26">
                                                <Accordion.Header>WHAT IS PAY IT FORWARD WALLET </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Your personal escrow wallets will receive ten reserve donations designated for the Pay it Forward wallet. The first five positions and positions 11 to 15 from the Level 2 matrix will contribute to Pay it Forward Space 1 Reserve Wallet. All ten reserve donations from each of the five spaces (Space 1 to Space 5) will be directed to one common Pay It Forward wallet.</p>
                                                    <p>Pay It Forward system promotes kindness and generosity, creating a ripple effect of positive actions for society. Active donors can utilize the funds from this Pay It Forward wallet to give a gift of USD 50 activation fee for a new member in Space 1 position. This act of kindness can make a positive impact on someone's life and provide you with a sense of life purpose. By using the funds from the reserved Pay it Forward wallet, you can offer a free Space 1 membership to help someone who can’t afford to join our Hi.Space community.</p>
                                                    <p>Pay It Forward highlights the power of small acts, fostering a world that values and celebrates selflessness and kindness. Each pay it forward activation will result in inviter receiving an invite donation, along with multiple donations from automatic re-entry and automatic upgrade positions.</p>
                                                    <p>For your information, the Pay It Forward wallet can only hold a maximum of USD 500 at any given time. If the balance exceeds USD 500, the smart contract will automatically keep USD 55 to the designated Pay It Forward reserve wallet and send the remaining balance to the upgrade wallet in space 2. This method will accelerate the matrix movement of space 3 positions for everyone in our Hi5 Space community.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="27">
                                                <Accordion.Header>WHAT IS SPACE COMMUNITY REWARDS</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>The Space Community Reward Fund seems to be a mechanism where members of the Hi5 space community can contribute to a fund that supports charitable endeavors within the space community. It's great to see that there's an emphasis on supporting individuals or organizations who have actively participated in charity activities themselves or have received donations from Hi5 Space community.</p>
                                                    <p>This reward fund can have a positive impact on promoting philanthropy within the Hi5 Space community and supporting projects, initiatives, or individuals that are working towards charitable and benevolent goals related activities.</p>

                                                    <p>By allowing Hi5 Space members who are unable to actively participate in community charity activities to contribute a portion of what they receive from the Hi5 Space community, you're ensuring that even those who might not have the time or resources to engage directly in charitable endeavors can still make a meaningful contribution to the greater cause.</p>

                                                    <p>The Space Community Reward Fund can foster a sense of unity, collaboration, and generosity within the space community, while also promoting the advancement of positive and impactful projects in the related fields. We extend an invitation to all active donors, inviting them to contribute a small portion to the Space Reward Fund. Through synergistic  efforts, we can collectively drive worldwide transformation by helping the needy people through the Hi5 Space Social Entrepreneurship Program.</p>

                                                    <p>Every month, starting on the 5th day, the Space Reward Fund Sharing becomes active. When the collected reward funds hit $500, the Space Loop smart contract evenly splits 50% of the reward amount among qualified participants and rolls over the remaining 50% to the following month.</p>
                                                    <p>In order to qualify for space reward funds, individuals need to showcase an image of their charitable activity on the space wall. This should include the Hi5 space logo worn on clothing items like shirts, hats, flags, or any other identification associated with the Hi5 space community.</p>
                                                    <h6>LET'S WORK TOGETHER TO EMPOWER UNITY AND BENEVOLENCE WITHIN THE Hi5 SPACE COMMUNITY.</h6>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="28">
                                                <Accordion.Header>WHAT IS S.A.M. PROJECT ?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Welcome to the S.A.M. Project (Space Acceleration Movement Project), a revolutionary initiative crafted to empower global social entrepreneurs like yourself. Our mission? To ignite a wildfire of fundraising opportunities through the vibrant engagement of passionate individuals and forward-thinking organizations. Joining forces with the S.A.M. Project means stepping into a transformative journey that transcends borders, leaving a profound and lasting legacy on communities worldwide.</p>
                                                    <p>The S.A.M. Project offers an innovative fundraising method by using 6 personal positions for the initial stage, with a starter entry cost of 6 x USD 50 = USD 300, and you'll secure six personal positions within your Space 1 matrix. Your task is to build a Space 1 matrix network by inviting 25 visionary individuals or organizational sponsors into the S.A.M. Project, each starting with their own 6 personal positions, just like you. With just 25 direct, committed teammates, you will complete levels 1, 2, and 3 of your Space 1 matrix. As a result, you will successfully collect USD 3450 for your first ID position in the matrix.</p>
                                                    <p>But the magic doesn't stop there. When 25 of your sponsored members replicate your role and you assist them, they will each collect USD 3450 for their first ID position in the matrix. Consequently, you will collect for the next 5 matrix positions, achieving a total of 6 x USD 3450, which equals USD 20,700 for all your starting positions. When there is sufficient funds in your escrow wallet, auto upgrades and re-entries will occur automatically multiple times, generating various rebirth IDs from Space 1 to Space 5 and creating a continuous stream of funds for everyone in our Hi5 Space community.</p>
                                                    <p>The S.A.M. Project empowers you to enrich lives and create a sustainable social impact on the global community. By joining this initiative, you become part of a movement that transcends borders and brings positive change to individuals and communities worldwide.</p>
                                                    <p>Together, we can harness the power of collective action to drive innovation, foster collaboration, and uplift those in need. Your involvement in the S.A.M. Project not only benefits you but also contributes to building a brighter, more inclusive future for all. Join us today and be a catalyst for transformation. Together, let's make a difference that resonates across the globe.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="28">
                                                <Accordion.Header>LEGALITY</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Donation crowdfunding serves as a platform where individuals and organizations can access the kindness and generosity of people who share a passion for a cause. It is a potent tool for raising awareness and garnering support for important social issues. Donation crowdfunding, also referred to as charitable crowdfunding, is a widely accepted practice that is generally legal in many countries.</p>
                                                    <p>We ensure that all donations made to our community members are entirely voluntary and free from any form of coercion. Our movement thrives on the principles of community donation crowdfunding, relying solely on the genuine generosity and goodwill of our social entrepreneurs, members of the community who are dedicated to making a positive impact.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="29">
                                                <Accordion.Header>CAN THE CONTRACT BE STOPPED/HACKED</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>This is a smart contract blockchain project. There is nothing to be halted as there are no deposits in the system, and all transfers are made irrevocably between the participants through the smart contract and all donations are transferred in real time. Our smart contracts have been developed with utmost care, guaranteeing bug-free and secure transactions for all donations and transfers.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="30">
                                                <Accordion.Header>ARE THERE ANY RISK</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Hi5 Space, a global, online donation crowdfunding system, operates fully on the Polygon Smart Contract, utilizing the revolutionary power of blockchain technology. This innovative platform empowers social entrepreneurs worldwide to actively drive innovative solutions for the advancement of society. The system is completely decentralized, meaning that there are no companies, administrators, middlemen, or business plans involved. Donations are delivered directly to the Hi5 Space community, ensuring that no one can manipulate the system and that there is no risk involved.</p>
                                                    <p>By excluding human factors, the system provides 100% security with transparent peer-to-peer transactions, allowing users to receive all donations through their private wallet. With our platform being compatible with over 100 wallets, you can choose any private wallet, making it simple for everyone to make a positive difference.The Hi5 Space Loop System connects people around the world, transforming givers into receivers and providing a community transformation project by the people for the people. Even without inviting anyone to join the movement, Hi5 Space users have the opportunity to effortlessly receive matrix donations, thanks to the unique architecture of the Hi5 Space Loop Super Technology, which automatically upgrades and re-enters in all five spaces (Space 1 to Space 5). Ultimately, the Hi5 Space project demonstrates the potential of social entrepreneurship to create systems that benefit the community at large, providing a sustainable and equitable way to enrich everyone in the community.</p>
                                                    <h6>Hi5 SPACE IS BORN TO CHANGE THE WORLD.</h6>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                        </Accordion>

                                    </div>
                                </Fade>
                            </Col>
                            {/* <Col lg={1} md={1}></Col>
                            <Col lg={4} md={5} className="mb-4">
                                <Card className="bg-dark p-5 rounded-5 text-center Boxsticky">
                                    <div className="">
                                        <h2 className="text-primary mb-3">Sign Up Today!</h2>
                                        <p>The world's first next-generation social entrepreneurship accelerator program brings together visionary individuals and community members who are committed to driving positive change. </p>
                                        <Button className="col-lg-5 mx-auto">Sign Up</Button>
                                    </div>
                                    <div className="dashboardImg">
                                        <img src="assets/images/faq/dashboard.png" />

                                    </div>
                                </Card>
                            </Col> */}
                        </Row>
                    </Container>

                </section>
            </div>
            <div className="globe_earth">
                <Footer />
            </div>
        </>
    );
};

export default Faq;
