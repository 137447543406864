import React, { useState,useEffect } from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import "../componentCss/about.css";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlay } from "react-icons/fa";
import {getAboutImg,getAboutVdo} from "../utils/apiServices"
import config from "../../coreFiles/config"
const About = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [img,setImg]=useState([])
  const [vdo,setVdo]=useState([])

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: "60px",
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(()=>{

    callEffect()
  },[])

  const callEffect=async()=>{
    try{
      let imgRes = await getAboutImg()

      if(imgRes.data.success){
        setImg(imgRes.data.data)
      }
      let vdoRed = await getAboutVdo()
      if(vdoRed.data.success){
        setVdo(vdoRed.data.data)
      }
    }catch(err){
      console.log(err)
    }
  }
  return (
    <>
      <Header />
      <div className="subheader about_bg" style={{ marginTop: "70px" }}>
        <div className="mt-5 text-center">
          <h1>Hi5 Space Social Enterpreneur</h1>
          <h2 className=" text-uppercase fw-medium">

            <span className="text-primary">About</span> Us
          </h2>
        </div>
      </div>

      <div
        className="bg-galaxy py-lg-5"
        style={{ backgroundPosition: "bottom" }}
      >
        <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="heading mb-3">
                  <h2>
                    <span className="text-primary">HI5 SPACE-</span>
                  </h2>
                  <h2>
                    BORN TO CHANGE THE WORLD BY{" "}
                    <span className="">NURTURING</span> MORE SOCIAL
                    ENTREPRENEURS
                  </h2>
                </div>
                <div>
                  <p>
                    Hi5 Space is a global platform that aims to foster a
                    community of social entrepreneurs who are passionate about
                    creating positive change in the world. Driven by a desire to
                    make a meaningful impact on individuals and communities in
                    need, they are dedicated to building a better future.
                  </p>
                  <p>
                    Hi5 Space is a brilliantly developed system by a team of
                    social entrepreneurs. It is an independent socio-economic
                    movement created by the people for the people to inspire,
                    motivate, educate, liberate, and elevate the lifestyles of
                    all Hi5 Space community members.
                  </p>
                  <p>
                    Hi5 Space is a movement of people helping people with 100%
                    donation sharing set protocols using an intelligent
                    algorithm system by polygon smart contract. It is a fully
                    automated system using smart contracts, with zero risk, and
                    there is no company, admin, or middleman involved. You can
                    receive your donations directly into your private wallet,
                    which guarantees the safety and security of your donations.
                  </p>
                  <p>
                    With our platform being compatible with over 100 wallets,
                    you can choose any private wallet, making it simple for
                    everyone to make a positive difference. Hi5 Space community
                    members only involve themselves in the reciprocal donation
                    sharing movement. Our reciprocal donations promote
                    collaboration, cooperation, and a sense of shared
                    responsibility. They recognize that everyone has something
                    to contribute and that by working together, we can make a
                    greater impact and create positive change. There is no
                    business model or investment plan on this platform.
                  </p>
                  <p>
                    Hi5 Space is a platform that enables individuals to become
                    successful social entrepreneurs by providing them with
                    excellent opportunities to generate financial resources. By
                    bringing together committed social entrepreneurs from around
                    the world, Hi5 Space has the potential to foster
                    collaboration, knowledge sharing, and impactful solutions to
                    various social challenges, thus enabling us to change the
                    world through unity in diversity and equality.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} md={12}>
                <Row>
                  <Col lg={5} className="mb-2">
                    <Fade top>
                      <div className="heading">
                        <h2 className="fw-bold lh-base mb-0 text-uppercase">
                          <span className="text-primary">
                            WELCOME TO Hi5 SPACE
                          </span>
                        </h2>
                      </div>
                    </Fade>
                  </Col>
                  <Col lg={12}>
                    <p className="">
                      The world's first next-generation social entrepreneurship
                      accelerator program brings together visionary individuals
                      and community members who are committed to driving
                      positive change.{" "}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className=" my-2">
                <Zoom>
                  <Card className="bg-light-grey py-5 px-3 rounded-5 border border-dark">
                    <div className="d-flex">
                      <div className="me-3">
                        <img src="assets/images/icon/about-icon/best-price.png" />
                      </div>
                      <div>
                        <h5>BUILD STRONGER GLOBAL COMMUNITY</h5>
                        <p className="mb-0">NO MONEY</p>
                        <p className="mb-0">NO PROBLEM</p>
                      </div>
                    </div>
                  </Card>
                </Zoom>
              </Col>
              <Col lg={4} className=" my-2">
                <Zoom>
                  <Card className="bg-light-grey py-5 px-3 rounded-5 border border-dark">
                    <div className="d-flex">
                      <div className="me-3">
                        <img src="assets/images/icon/about-icon/monitor.png" />
                      </div>
                      <div>
                        <h5>EQUAL OPPORTUNITY TO SUCCEED</h5>
                        <p className="mb-0">NO PERSONAL DIRECT</p>
                        <p className="mb-0">NO PROBLEM</p>
                      </div>
                    </div>
                  </Card>
                </Zoom>
              </Col>

              <Col lg={4} className=" my-2">
                <Zoom>
                  <Card className="bg-light-grey py-5 px-3 rounded-5 border border-dark">
                    <div className="d-flex">
                      <div className="me-3">
                        <img src="assets/images/icon/about-icon/teamwork.png" />
                      </div>
                      <div>
                        <h5>
                          BRIGHTER FUTURE <br /> FOR EVERYONE
                        </h5>
                        <p className="mb-0">NO INVOLVEMENT</p>
                        <p className="mb-0">NO PROBLEM</p>
                      </div>
                    </div>
                  </Card>
                </Zoom>
              </Col>
              <Col lg={12} className="mt-3">
                <div>
                  {!isCollapsed && (
                    <div>
                      <p>
                        If you don’t have the funds to become an active donor,
                        don't worry. Registration on Hi5 Space is free, and as a
                        free member, you'll be considered an inactive donor. The
                        smart contract provides a 50-day window for you to
                        upgrade to active donor status. During this period, even
                        as an inactive donor, you can still invite your direct
                        contacts using your personal invite link. Once they
                        become active donors, you'll receive unlimited 20%
                        invite donations from all their re-entries and upgraded
                        positions.
                      </p>
                      <p>
                        As soon as you become an active donor, you can make a
                        positive impact in someone's life by activating them to
                        become active donors through your Auto Pay It Forward
                        system. If you don’t have any personal direct referrals,
                        there's no need to worry. As an active donor in our Hi5
                        Space community, you can still receive 80% community
                        donations multiple times for spaces 2 to 5 from each
                        position, based on the automated global powerline
                        placement.
                      </p>
                      <p>
                        You won't have to wait for a complete 5 by 3 matrix for
                        any upgrades and re-entries. Multiple upgrade and
                        re-entry positions will automatically occur once there
                        is enough money in your personal escrow wallet.
                      </p>
                    </div>
                  )}
                  <div className="text-center">
                    <Button
                      variant="primary"
                      className="my-3 px-4"
                      onClick={handleToggleCollapse}
                    >
                      {isCollapsed ? "Show More" : "Show Less"}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">
          <Container>
            <Row>
              <Col lg={6} className="mb-4">
                <div className="d-flex align-items-center">
                  <div className="bigImage">
                    {/* <img src="assets/images/about/teamwork.webp" /> */}
                    {img.length>0 && <img src={`${config.ApiURL}aboutPage/${img[0].fileName}`} />}
                  </div>

                </div>
              </Col>
              <Col lg={6} className="mb-4">
                <div className="videoImg position-relative">
                  {/* <img src="assets/images/about/officework.png" /> */}
                  {vdo.length>0 && <video controls width="90%">
                <source
                      src={`${config.ApiURL}aboutPage/${vdo[0].fileName}`}
                      type="video/mp4"
                    />
                  </video>}
                  {/* <div className="video-icon"><FaPlay fill="#FF1B1B" /></div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="py-lg-5 py-md-5 py-sm-5 pt-3 position-relative">
    <Container>
        <Row>
            <Col lg={12}>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="videoContainer">
                        <video controls width="100%">
                            <source src="https://hi5space.espsofttech.org/Hi5Video.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
</section> */}

        {/* <section className="py-lg-5 py-md-5 py-sm-5  pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8} md={8}>
                                <Fade top>
                                    <div className="heading text-center mb-4">
                                        <h2 className="fw-bold lh-base mb-0 text-uppercase">Our <span className="text-primary">team</span></h2>
                                        <p>United we stand, a team fueled by passion and synergy. Together, we conquer challenges and forge success with unwavering determination</p>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={12} className=" my-3">
                                <Zoom>
                                    <Slider {...settings}>
                                        <div>
                                            <div className="team">
                                                <img src="assets/images/about/lelo.png"/>
                                                <div className="text-box text-black">
                                                    <h5>Lilo Maximillan</h5>
                                                    <p className="mb-0 fw-medium">Founder & CEO</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="team">
                                                <img src="assets/images/about/john.png"/>
                                                <div className="text-box text-black">
                                                    <h5>John Doe</h5>
                                                    <p className="mb-0  fw-medium">COO</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="team">
                                                <img src="assets/images/about/isabella.png"/>
                                                <div className="text-box text-black">
                                                    <h5>Isabella grey</h5>
                                                    <p className="mb-0  fw-medium">Director</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="team">
                                                <img src="assets/images/about/lelo.png"/>
                                                <div className="text-box text-black">
                                                    <h5>Lilo Maximillan</h5>
                                                    <p className="mb-0 fw-medium">Founder & CEO</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="team">
                                                <img src="assets/images/about/john.png"/>
                                                <div className="text-box text-black">
                                                    <h5>John Doe</h5>
                                                    <p className="mb-0  fw-medium">COO</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="team">
                                                <img src="assets/images/about/isabella.png"/>
                                                <div className="text-box text-black">
                                                    <h5>Isabella grey</h5>
                                                    <p className="mb-0  fw-medium">Director</p>
                                                </div>
                                            </div>
                                        </div>

                                      
                                    </Slider>
                                </Zoom>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
      </div>
      <div className="globe_earth">
        <Footer />
      </div>
    </>
  );
};

export default About;
