module.exports = {
    
    usdtConrtactAddress:'0x30AEA89b5bd13e4d593247667086AF421c43B333',
    projectId: 'f5977011bdf8f1a31a1ea68aa2d4a606',
    contractAdress:
    "0xc01DC844b9BDFD591658d93F1f1Fb269A4076398",
    CHAIN:"80001",
    adminAddress:"0x575fBccf7966347C1e299921979c707Dd5cd5941",
    testneturl:"https://testnet.bscscan.com/tx/",
    chainId:97
}