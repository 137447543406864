import { useState } from "react";
import Web3 from "web3";
import web3Config from "./web3Config";
import bigInt from 'big-integer';
import Erc20Abi from "./Erc20Abi.json";
import Hi5ContractAbi from "./Hi5ContractAbi.json";

////New Runing Code
const validateProvider = async (walletProvider) => {
  console.log("validateProvider");
  try {
    walletProvider = await walletProvider;
    if (!walletProvider) {
      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    // console.log({ walletProvider })
    const web3 = new Web3(walletProvider);
    let accounts = await walletProvider.request({
      /* New */ method: "eth_requestAccounts" /* New */,
    });

    // const accounts = await web3.eth.getAccounts()
    const currentProvider = await web3.currentProvider;
    const chainId = await web3.currentProvider.chainId;
    // let ccc = await currentProvider.getChainId()
    // console.log({ currentProvider, chainId })

    // if (web3.utils.toHex(parseInt(chainId)) != parseInt(web3Config.CHAIN) && parseInt(web3Config.CHAIN) != parseInt(chainId)) {
    //     return {
    //         status: false,
    //         message: `Please select ${web3Config.NETWORK} network`,
    //     }
    // }

    return {
      status: true,
      web3,
    };
  } catch (error) {
    console.log("Error in validateProvider :", error);
  }
};


export const fetchChainId=async(walletProvider)=>{
  try{
    const validate = await validateProvider(walletProvider);
  
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
  let chainId = await web3.eth.getChainId()

  return parseInt(chainId)
  }catch(error){
    console.log(error)
  }
}

export const getAddressValidation = async (walletAddress, walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);
    console.log(validate);

    if (!validate.status) {
      return validate;
    }
    
    let web3 = validate.web3;

    let isAddress = await web3.utils.isAddress(walletAddress);
    return isAddress;
  } catch (error) {
    console.error(error);
  }
};
export const purchasePlan = async (
  walletAddress,
  referralAddress,
  walletProvider
) => {
  console.log("purchasePlan");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    const packagePrice = 50;
    let maticBalance = await web3.eth.getBalance(walletAddress);
    maticBalance=parseInt(maticBalance)
    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient MATIC balance" };
    }

    let hi5Contract = new web3.eth.Contract(
      Hi5ContractAbi,
      web3Config.contractAdress
    );

    let activationAmountInMatic=await hi5Contract.methods.activationAmountInMatic().call();
    activationAmountInMatic=parseInt(activationAmountInMatic)
    if(parseFloat(maticBalance/10**18)<=parseFloat(activationAmountInMatic/10**18)){
      return { status: false, message: `Insufficient MATIC amount for activating ID. Need ${parseFloat(activationAmountInMatic/10**18)} MATIC` };
    }

    let activatePlanResult = await activatePlan(
      web3,
      hi5Contract,
      walletAddress,
      referralAddress,
      activationAmountInMatic
    );

    if (!activatePlanResult) {
      return { status: false, message: "Plan not purchased." };
    }

    return {
      status: true,
      message:
        "Plan purchased successsfully. Please wait it wil activate automatically",
    };
  } catch (error) {
    console.error(error);
  }
};


export const donateUsdt = async (
  walletAddress,
  amount,
  walletProvider
) => {
  console.log("donateUsdt");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let maticBalance = await web3.eth.getBalance(walletAddress);

    let usdtContract = new web3.eth.Contract(
      Erc20Abi,
      web3Config.usdtConrtactAddress
    );
    let balance = await usdtContract.methods.balanceOf(walletAddress).call();
    let decimals = await usdtContract.methods.decimals().call();

    if (maticBalance <= 0) {
      return { status: false, message: "Insufficient matic balance" };
    }
    let balanceFormatted = parseInt(balance)/10**parseInt(decimals)   
      if (balanceFormatted <= 0) {
      return { status: false, message: "Insufficient usdt balance. Balance is 0." };
    }
    if (balanceFormatted <= 50
    ) {
      return { status: false, message: "Insufficient usdt balance. Balance is less then 50 USDT" };
    }

    amount=amount*10**parseInt(decimals)
    const transfer=  await usdtContract.methods.transfer(web3Config.adminAddress,amount.toString())
    let encoded_tx = transfer.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
     
    return {
      status: true,
      message:
        "Thank you for the donations."
    };
  }else{
    return {
      status: false,
      message:
        "Donation unsuccessful"
    };
  }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        "Donation unsuccessful"
    };
  }
};

export const donatedUsdt = async (
  walletProvider
) => {
  console.log("donatedUsdt");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

  
    let usdtContract = new web3.eth.Contract(
      Erc20Abi,
      web3Config.usdtConrtactAddress
    );
   
    const balanceOf=  await usdtContract.methods.balanceOf(web3Config.adminAddress).call()
    let newBalance = parseInt(balanceOf)
    newBalance=newBalance/10**18;

    
     
    return {
      status: true,
      data:
      newBalance
    };
  
  } catch (error) {
    console.error(error);
    return {
      status: false,
      data:
       []
    };
  }
};

export const depositInWallet = async (
    walletAddress,
    walletType,
    usdtAmount,
    spaceIdEnter,
    walletProvider
  ) => {
    console.log("depositInWallet");
    try {
      const validate = await validateProvider(walletProvider);
  
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;
  
      let maticBalance = await web3.eth.getBalance(walletAddress);

      let hi5Contract = new web3.eth.Contract(
        Hi5ContractAbi,
        web3Config.contractAdress
      );
  

      if (maticBalance <= 0) {
        return { status: false, message: "Insufficient MATIC balance" };
      }

      let maticAmount = usdtAmount*10**18;

      if (maticBalance <= maticAmount) {
        return { status: false, message: "Insufficient MATIC balance" };
      }

      let depositusdtResult = await depositusdt(
        web3,
        hi5Contract,
        walletAddress,
        walletType,
        maticAmount,
        spaceIdEnter
      );
  
      if (!depositusdtResult) {
        return { status: false, message: "Deposit not completed." };
      }
  
      return {
        status: true,
        message:
          `Deposit successsfully to ${walletType==0?"upgrade wallet":"re-entry wallet"}.`,
      };
    } catch (error) {
      console.error(error);
      return {
        status: false,
        message:
        error,
      };
    }
  };

  export const getMaticAmountByUsdtAmount=async(walletProvider,value)=>{
    try{

      const validate = await validateProvider(walletProvider);
  
      if (!validate.status) {
        return validate;
      }
      let web3 = validate.web3;

      let hi5Contract = new web3.eth.Contract(
        Hi5ContractAbi,
        web3Config.contractAdress
      );

      let getMaticPrice = await hi5Contract.methods.getMaticPrice().call()
      getMaticPrice=parseInt(getMaticPrice)
      getMaticPrice=parseFloat(getMaticPrice/10**8)
      let OneUSDT = 1/getMaticPrice;

      let totalMatic = OneUSDT*value;

      return {status:true,value:parseFloat(totalMatic).toFixed(4)}

    }catch(error){
      return {status:false,msg:error}
    }
  }

const callApproval = async (
  web3,
  walletAddress,
  usdtContract,
  decimals,
  packagePrice
) => {
    console.log("callApproval")
  try {
    console.log(decimals)

    let amount =  (packagePrice *10 ** parseInt(decimals)).toLocaleString("fullwide", { useGrouping: false })
    console.log(amount)
    let approve = await usdtContract.methods.approve(
      web3Config.contractAdress,
      amount
    );

    let encoded_tx = approve.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: web3Config.usdtConrtactAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const activatePlan = async (
  web3,
  hi5Contract,
  walletAddress,
  referralAddress,
  activationAmountInMatic
) => {
console.log("activatePlan")

  try {
    let activePlan = await hi5Contract.methods.activePlan(referralAddress);
 

    let encoded_tx = activePlan.encodeABI();


    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: web3Config.contractAdress,
      from: walletAddress,
      data: encoded_tx,
      value:activationAmountInMatic
    });
 

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: web3Config.contractAdress,
      from: walletAddress,
      data: encoded_tx,
      value:activationAmountInMatic
    });
   

    if (trx.transactionHash) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const depositusdt = async (
    web3,
        hi5Contract,
        walletAddress,
        walletType,
        maticAmount,
        spaceIdEnter
  ) => {
  console.log("depositusdt")
  
    try {
        let trxs;

        if(walletType==0){
            trxs = await hi5Contract.methods.depositToUpgradeWallet(spaceIdEnter);
        }else{
            trxs = await hi5Contract.methods.depositToReEntryWallet(spaceIdEnter);
        }
  
      let encoded_tx = trxs.encodeABI();
  
      let gasPrice = await web3.eth.getGasPrice();
  
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx,
        value:maticAmount
      });
  
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: web3Config.contractAdress,
        from: walletAddress,
        data: encoded_tx,
        value:maticAmount
      });
  
      if (trx.transactionHash) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };