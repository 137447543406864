import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Offcanvas,
  Modal,
  Row,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";
import { purchasePlan, depositInWallet } from "../../Web3/Connector";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  dashboardDetails,
  getDirectReferralsByIdApi,
  activateUser,
  piWalleBalance,
  getSpaceInfoByUserId,
  visualize
} from "../../utils/apiServices";
import { Label } from "flowbite-react";
import BasicPie from "../../../component/Dashboard/newPieChart copy";
import { TailSpin } from "react-loader-spinner";

const MatrixMembers = () => {
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const [loader,setLoader]=useState(false)

  const [activeSpace, setActiveSpace] = useState(1);
  const [dashboardDetail, setDashboardDetail] = useState([]);
  
  const [details, setDetails] = useState([]);

  const [show, setShow] = useState(false);
  const [show20, setShow20] = useState(false);
  const [vizualiseId, setVizualiseId] = useState("");
  const [vizualiseId2, setVizualiseId2] = useState("");

  const [levelFirst, setLevelFirst] = useState([]);
  const [levelSecond, setLevelSecond] = useState([]);
  const [levelThird, setLevelThird] = useState([]);

  const [vizualiseData, setVizualiseData] = useState([]);
  
  const handleClose = () => {
    setShow(false);
    setShow20(false);
  }

  useEffect(() => {
    fetchDashboardDetails(1);
  }, []);

  const fetchDashboardDetails = async (id) => {
    try {
      setLoader(true)
      console.log("fetchDashboardDetails")
      let payload = loginDetails;
      payload["space"] = id;
      let result = await dashboardDetails(payload);
      console.log(result.data)
      if (result.data.success) {
        
        setDashboardDetail(result.data.data)
      setLoader(false)

      } else {
        toast.error(result.data.message);
      setLoader(false)

      }
    } catch (error) {
      console.log(error)
      setLoader(false)

     }
  };

  useEffect(() => {
    //fetchLevelDetails();
  }, []);

  const fetchLevelDetails = async () => {
    try {
      let payload = {
        userId: loginDetails.id,
        activeSpace: activeSpace,
      };
      let result = await getSpaceInfoByUserId(payload);
      if (result.data.success) {
        setDetails(result.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeSpace = async (e, space) => {
    e.preventDefault();
    try {
      setLoader(true)
     
      setActiveSpace(space);

      fetchDashboardDetails(space)
      // let payload = {
      //   userId: loginDetails.id,
      //   activeSpace: space,
      // };
      // let result = await getSpaceInfoByUserId(payload);
      // if (result.data.success) {
       
      //   setDetails(result.data.result);
      // }
    } catch (error) {
      console.log(error);
      setLoader(false)

    }
  };

  const objectToArray = (obj) => {
    return Object.keys(obj).map((key) => ({ key, value: obj[key] }));
  };

  const handleShowVisualize = async (e, id) => {
    e.preventDefault();
    try {
      setVizualiseId(id);
      let payload = {
        id,
        space: parseInt(activeSpace),
      };

      let response = await visualize(payload);
      if (response.data.success) {
        setShow(true);
        setVizualiseData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleShowVisualize2 = async (e, id) => {
    e.preventDefault();
    try {
      setVizualiseId2(id);
      let payload = {
        id,
        space: parseInt(activeSpace),
      };

      let response = await visualize(payload);
      if (response.data.success) {
        setShow20(true);
        setLevelFirst(response.data.pieData[0].level);
        setLevelSecond(response.data.pieData[1].level);
        setLevelThird(response.data.pieData[2].level);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        { loader &&<div className="mainLoader">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#e1b028"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>}
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
            
          <Row>
            <Col lg={6} className=""></Col>

            <Col lg={12} className="">
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Matrix Members</h5>
                  </div>
                  {dashboardDetail.length > 0 &&
                  <div>
                    {" "}
                    {[1, 2, 3, 4, 5].map((item, index) => (
                     item<=dashboardDetail[0].space?
                      <Button
                        onClick={(e) => {
                          changeSpace(e, item);
                        }}
                        className="me-3 mb-3"
                      >
                        Space {item}
                      </Button>
                    :
                    <Button
                    disabled
                    className="me-3 mb-3"
                  >
                    Space {item}
                  </Button>
                    ))}
                  </div>}
                  <Label className="mb-3">Space : {activeSpace}</Label>
                  <Card className="cardBox overflow-hidden sameHeight">
                    <div className="d-table table-responsive">
                      {/* <Table striped hover className="text-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="text-uppercase">Level</th>
                            <th className="text-uppercase">Member Counts</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details.length > 0 &&
                            details.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                {objectToArray(item).map((pair, idx) => (
                                  <React.Fragment key={idx}>
                                    <td>{pair.key}</td>
                                    <td>{pair.value}</td>
                                  </React.Fragment>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </Table> */}



                      <Table
                        responsive
                        striped
                        hover
                        className="text-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Types</th>
                            <th>Level 01 </th>
                            <th>Level 02</th>
                            <th>Level 03</th>
                            <th>Matrix</th>
                            <th>Deposit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].visualisation.map((item) => (
                              <tr>
                                <td>{item.id}</td>
                                <td>{item.type}</td>
                                <td>{item.level1}</td>
                                <td>{item.level2}</td>
                                <td>{item.level3}</td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    onClick={(e) => {
                                      handleShowVisualize2(e, item.id);
                                    }}
                                  >
                                    Visualize
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    onClick={(e) => {
                                      handleShowVisualize(e, item.id);
                                    }}
                                  >
                                    Payment
                                  </Button>
                                </td>
                              </tr>
                            ))}


                        </tbody>
                      </Table>
                 
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>

            {/* ----------payment-------- */}
            <Modal
          show={show}
          onHide={handleClose}
          centered
          className="d-modal"
          size="lg"
        >
          <Modal.Header closeButton className="py-2">
            <Modal.Title className="fs-5">
              Payment <span className="text-primary">ID {vizualiseId}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mb-0 bg-white">
            <div className="text-center piechart mb-3">
              {/* <img src="assets/images/dashboard/piechart.svg" /> */}
              {/* <Chart /> */}
            </div>

            <Table responsive bordered hover className="mb-0 text-center">
              <thead>
                <tr>
                  <th>level</th>
                  <th>Wallet</th>
                  <th>Donation</th>
                  <th>Received</th>
                </tr>
              </thead>
              <tbody>
                {vizualiseData.length > 0 &&
                  vizualiseData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.Wallet}</td>
                      <td>USD {item.Donation}</td>
                      <td>USD {item.Recieved}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        {/* ----------Visualize-------- */}
        <Modal
          show={show20}
          onHide={handleClose}
          centered
          className="d-modal"
          size="xl"
        >
          <Modal.Header closeButton className="py-2">
            <Modal.Title className="fs-5">
              Visualize <span className="text-primary">ID {vizualiseId2}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mb-0 bg-white">
            <div className="text-center piechart mb-3">
              {/* <img src="assets/images/dashboard/piechart.svg" /> */}
              {/* <Chart /> */}
            </div>
            <Row>
              <Col lg={4} className="mb-3">
                <p className="text-center fw-bold fs-5">Level 1</p>
                {levelFirst.length > 0 ? (
                  <BasicPie
                    data={levelFirst}

                  />
                ) : (
                  <p className="d-flex justify-content-center align-items-center h-75 fs-5 fw-bold">No data</p>
                )}
              </Col>
              <Col lg={4} className="mb-3">
                <p className="text-center fw-bold fs-5">Level 2</p>
                {levelSecond.length > 0 ? (
                  <BasicPie data={levelSecond} />
                ) : (
                  <p className="d-flex justify-content-center align-items-center h-75 fs-5 fw-bold">No data</p>
                )}
              </Col>
              <Col lg={4} className="mb-3">
                <p className="text-center fw-bold fs-5">Level 3</p>
                {levelThird.length > 0 ? (
                  <BasicPie data={levelThird} />
                ) : (
                  <p className="d-flex justify-content-center align-items-center h-75 fs-5 fw-bold">No data</p>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
    </>
  );
};

export default MatrixMembers;
