import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import web3Config from './component/Web3/web3Config';
import { polygon,polygonMumbai,bscTestnet } from 'viem/chains';
import store, { persistor } from "./component/redux/storeConfig";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
const root = ReactDOM.createRoot(document.getElementById('root'));


const chains = [ bscTestnet]
const projectId = web3Config.projectId;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

root.render(
  <>
  <WagmiConfig config={wagmiConfig}>
  <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <App />
          </PersistGate>
      </Provider>
  </WagmiConfig>
    <Web3Modal themeMode='dark' themeVariables={{"--w3m-z-index":'99999'}} projectId={projectId} ethereumClient={ethereumClient} />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
