import React, { useState, useEffect } from "react";
import copy from "copy-to-clipboard";
import {
    Container,
    Row,
    Col,
    Card,
    Table
} from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { getInviteDonations } from '../../utils/apiServices.js'


const Invitedonation = () => {
    const [transList, settransList] = useState([])
    const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);

    useEffect(() => {
        getTrxList()
    }, [])

    const getTrxList = async () => {
        let res = await getInviteDonations({ userId: loginDetails.id })
        if (res.data.success) {
            let data = res.data.data
            settransList(data)
        }
    }

    const copyText = (e,address) => {
        copy(address);
        alert(`You have copied "${address}"`);
    }

    // console.log('transList', transList)
    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">

                    <Row>
                        <Col lg={6} className="">
                        </Col>
                        {/* <Col lg={6} className="mb-4">
                            <div className="Tablist w-100">
                                <div className="d-sm-flex t-list mb-0 justify-content-between w-100 align-items-center">
                                    <div className="">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="pr-2 border-right">
                                                <h6 className="mb-0">Status</h6>
                                            </div>
                                            <div className="pl-2">
                                                <h5 className="text-primary mb-0">INACTIVE DONOR</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className=" border-0">
                                        <div className="">
                                            <h6 className="text-blue mb-0">50 Day Left</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col> */}
                        <Col lg={12} className="">
                            <Row>
                                <Col lg={12} className="mb-4">
                                    <div className="heading mb-3">
                                        <h5 className="text-uppercase mb-0">Space Transactions</h5>
                                    </div>
                                    <Card className="cardBox overflow-hidden sameHeight">
                                        <div className="d-table table-responsive">
                                            <Table striped hover className="text-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th className="text-uppercase">NAME</th>
                                                        <th className="text-uppercase">Space</th>
                                                        {/* <th className="text-uppercase">Wallet Address</th> */}
                                                        <th className="text-uppercase">Date</th>
                                                        <th className="text-uppercase">Transaction Type</th>
                                                        <th className="text-uppercase">Amount</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transList && transList.length > 0 && transList.map((item, index) => <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name!=null?item.name:"user"}</td>
                                                        <td>{item.plan_id}</td>
                                                        {/* <td>{item.FromwalletAddress}</td> */}
                                                        {/* <td>{item.address.toString().substring(0, 8) +
                                                            "...." +
                                                            item.address
                                                                .toString()
                                                                .substring(item.address.length - 8, item.address.length)}
                                                                <FaCopy type="button" onClick={e => copyText(e,item.address)} /></td> */}
                                                                {/* <td></td> */}
                                                        <td>{moment(item.datetime).format('DD/MM/YYYY')}</td>
                                                        <td>{item.transactionType}</td>
                                                        <td>${item.amount}</td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>

                                        </div>
                                    </Card>
                                </Col>

                            </Row>


                        </Col>

                    </Row>
                </Box>
            </Box>


        </>
    );
};

export default Invitedonation;
