import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Button,
  Accordion,
} from "react-bootstrap";
import Sidebar from "../../Sidebar";
import Box from "@mui/material/Box";
import "../../css/spacewall.css";
import "../../css/chat.css";
import {
  getQuestions,
  getUserBotQuestionsAndAnswers,
  insetIntoBotQuestions,
} from "../../../utils/apiServices";
import { useSelector } from "react-redux";

const Bothelp = () => {
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);

  const [questionsList, setQuestionsList] = useState([]);
  const [message, setMessages] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputField, setOpenInputFields] = useState(false);
  const [userQuestion, setUserQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    getQuestion();
  }, []);

  const getQuestion = async () => {
    try {
      let result = await getQuestions();
      let qArray = result.data.data;
      qArray.push({
        id: result.data.data.length + 1,
        question: "Other",
        answer: "Other",
      });
      setQuestionsList(qArray);

      let result1 = await getUserBotQuestionsAndAnswers({
        userId: loginDetails.id,
      });
      setMessages(result1.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "Other") {
      setOpenInputFields(true);
    } else {
      setOpenInputFields(false);
      const selectedQuestion = questionsList.find(
        (item) => item.question === selectedValue
      );
      setAnswer(selectedQuestion?.answer || "");
    }
    setSelectedOption(selectedValue);
  };

  const handleChangeOtherQuery = (event) => {
    setUserQuestion(event.target.value);
  };

  const handleInsertQuestion = async () => {
    if (!inputField) {
      const newMessage = {
        question: selectedOption,
        answer: answer,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    } else {
      const payload = {
        userId: loginDetails.id,
        question: userQuestion,
      };
      let result = await insetIntoBotQuestions(payload);
      if (result.data.success) {
        toast.success(
          "Question delivered successfully. Please wait for a reply from the admin"
        );
        getQuestion();
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Toaster />
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={12} className="mb-4 mt-5 pt-5">
              <Row className="justify-content-center">
                <Col lg={10}>
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">
                      Interact with Captain Hi5 Space
                    </h5>
                  </div>
                  <Card className="cardBox overflow-hidden p-0">
                    <div className="chat-accordion">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Space Bot Help</Accordion.Header>
                          <Accordion.Body className="pt-5">
                            <div className="messaging">
                              <div className="inbox_msg">
                                <div className="mesgs">
                                  <div className="msg_history">
                                    {message.length > 0 &&
                                      message.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <div className="outgoing_msg">
                                            <div className="sent_msg">
                                              <p>{item.question}</p>
                                            </div>
                                          </div>
                                          <div className="incoming_msg">
                                            <div className="received_withd_msg">
                                              <p>{item.answer}</p>
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                  </div>
                                  <div className="type_msg mt-5">
                                    <div
                                      className="input_msg_write"
                                      style={{ borderRadius: "8px" }}
                                    >
                                      <InputGroup className="mb-3">
                                        <Form.Select
                                          aria-label="Default select example"
                                          value={selectedOption}
                                          onChange={handleSelectChange}
                                        >
                                          <option value="">
                                            Select an option
                                          </option>
                                          {questionsList.length > 0 &&
                                            questionsList.map((item) => (
                                              <option
                                                key={item.id}
                                                value={item.question}
                                              >
                                                {item.question}
                                              </option>
                                            ))}
                                        </Form.Select>
                                        <Button
                                          variant="outline-secondary"
                                          className="msg_send_btn"
                                          id="button-addon2"
                                          onClick={handleInsertQuestion}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M1 10L20 1L11 20L9 12L1 10Z"
                                              fill="#1B1915"
                                              stroke="#1B1915"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </Button>
                                      </InputGroup>
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    {inputField && (
                                      <input
                                        className="write_msg form-control"
                                        value={userQuestion}
                                        placeholder="Please Enter Detail.."
                                        onChange={handleChangeOtherQuery}
                                      ></input>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>
    </>
  );
};

export default Bothelp;
