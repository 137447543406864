import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import Sidebar from "../../Sidebar";
import Box from "@mui/material/Box";
import "../../css/spacewall.css";
import "../../css/chat.css";
import { useSelector } from "react-redux";
import config from "../../../../coreFiles/config";
const socket = io(`${config.socketId}`);

const Teammateshelp = () => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);

  useEffect(() => {
    socket.on("initial messages", (initialMessages) => {
      setMessages(initialMessages);
    });

     socket.on("chat message", (msg) => {
       setMessages((prevMessages) => [...prevMessages, msg]);
     });

    return () => {
      socket.off("initial messages");
      socket.off("chat message");
    };
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    if (currentMessage.trim()) {
      let payLoad = {
        msg: currentMessage,
        userId: loginDetails.id,
      };
      socket.emit("chat message", payLoad);
      setCurrentMessage("");
    }
  };

  return (
    <>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={12} className="mb-4 mt-5 pt-5">
              <Row className="justify-content-center">
                <Col lg={10}>
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">
                      Interact with Hi5 Teammates
                    </h5>
                  </div>
                  <Card className="cardBox overflow-hidden p-0">
                    <div className="chat-accordion">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Teammate Help</Accordion.Header>
                          <Accordion.Body className="pt-5">
                            <div className="messaging">
                              <div className="inbox_msg">
                                <div className="mesgs">
                                  <div className="msg_history">
                                    {messages.map((item, index) =>
                                      item.userId != loginDetails.id ? (
                                        <div className="incoming_msg d-flex">
                                          <div className="incoming_msg_img">
                                            {" "}
                                            <div className="text-center">
                                              <img
                                                src={`${config.ApiURL}/profilePic/${item.profileImage}`}
                                                className="rounded-2 cht_img"
                                              />{" "}
                                            </div>
                                          </div>
                                          <div className="received_msg mb-2">
                                            <div className="received_withd_msg">
                                              <p>{item.message} </p>
                                              
                                              <span className="time_date">
                                                {/* {" "} */}
                                                {item.name != null
                                                  ? item.name
                                                  : item.uniqueId}    / {item.timestamp}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="outgoing_msg">
                                          <div className="sent_msg">
                                            <p>{item.message} </p>
                                            <span className="time_date">
                                              {" "}
                                              {item.timestamp}
                                            </span>{" "}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>

                                  <div className="type_msg">
                                    <div className="input_msg_write">
                                      <input
                                        type="text"
                                        className="write_msg"
                                        placeholder="Type a message"
                                        value={currentMessage}
                                        onChange={(e) =>
                                          setCurrentMessage(e.target.value)
                                        }
                                      />
                                      <button
                                        className="msg_send_btn"
                                        type="button"
                                        onClick={(e) => {
                                          sendMessage(e);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="21"
                                          height="21"
                                          viewBox="0 0 21 21"
                                          fill="none"
                                        >
                                          <path
                                            d="M1 10L20 1L11 20L9 12L1 10Z"
                                            fill="#1B1915"
                                            stroke="#1B1915"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>
      {/* <>
        <Box sx={{ display: "-webkit-box" }} className="dashboard">
          <Sidebar />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            style={{ marginTop: "74px" }}
            className="text-white mainBox"
          >
            <Row>
              <Col lg={12} className="mb-4 mt-5 pt-5">
                <Row className="justify-content-center">
                  <Col lg={10}>
                    <div className="heading mb-3">
                      <h5 className="text-uppercase mb-0">
                        Interact with Hi5 Teammates
                      </h5>
                    </div>
                    <Card className="cardBox overflow-hidden p-0">
                      <div className="chat-accordion">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Teammate Help</Accordion.Header>
                            <Accordion.Body className="pt-5">
                              <div className="messaging">
                                <div className="inbox_msg">
                                  <div className="mesgs">
                                    <div className="msg_history">
                                      <div className="incoming_msg">
                                        <div className="incoming_msg_img">
                                          {" "}
                                          <img
                                            src="assets/images/icon/teammate.jpeg"
                                            alt="teammate"
                                            className="rounded-2"
                                          />{" "}
                                        </div>
                                        <div className="received_msg">
                                          <div className="received_withd_msg">
                                            <p>
                                              Test which is a new approach to
                                              have all solutions
                                            </p>
                                            <span className="time_date">
                                              {" "}
                                              11:01 AM | June 9
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="outgoing_msg">
                                        <div className="sent_msg">
                                          <p>
                                            Test which is a new approach to have
                                            all solutions
                                          </p>
                                          <span className="time_date">
                                            {" "}
                                            11:01 AM | June 9
                                          </span>{" "}
                                        </div>
                                      </div>
                                      <div className="incoming_msg">
                                        <div className="incoming_msg_img">
                                          {" "}
                                          <img
                                            src="assets/images/icon/teammate.jpeg"
                                            alt="teammate"
                                            className="rounded-2"
                                          />{" "}
                                        </div>
                                        <div className="received_msg">
                                          <div className="received_withd_msg">
                                            <p>
                                              Test, which is a new approach to
                                              have
                                            </p>
                                            <span className="time_date">
                                              {" "}
                                              11:01 AM | Yesterday
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="outgoing_msg">
                                        <div className="sent_msg">
                                          <p>
                                            Apollo University, Delhi, India Test
                                          </p>
                                          <span className="time_date">
                                            {" "}
                                            11:01 AM | Today
                                          </span>{" "}
                                        </div>
                                      </div>
                                      <div className="incoming_msg">
                                        <div className="incoming_msg_img">
                                          {" "}
                                          <img
                                            src="assets/images/icon/teammate.jpeg"
                                            alt="teammate"
                                            className="rounded-2"
                                          />{" "}
                                        </div>
                                        <div className="received_msg">
                                          <div className="received_withd_msg">
                                            <p>
                                              We work directly with our
                                              designers and suppliers, and sell
                                              direct to you, which means
                                              quality, exclusive products, at a
                                              price anyone can afford.
                                            </p>
                                            <span className="time_date">
                                              {" "}
                                              11:01 AM | Today
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="type_msg">
                                      <div className="input_msg_write">
                                        <input
                                          type="text"
                                          className="write_msg"
                                          placeholder="Type a message"
                                        />
                                        <button
                                          className="msg_send_btn"
                                          type="button"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M1 10L20 1L11 20L9 12L1 10Z"
                                              fill="#1B1915"
                                              stroke="#1B1915"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Card>
                    <div className=" mt-4">
                      <div
                        className="received_msg"
                        style={{ padding: "0px 20px 0px 0px" }}
                      >
                        <div className="received_withd_msg w-100 text-black">
                          <p style={{ padding: "15px 10px 15px 12px" }}>
                            Hello Buddy! Welcome to our website, is there
                            anything i can help you with
                          </p>
                        </div>
                      </div>
                      <div className="incoming_msg_img">
                        {" "}
                        <img
                          src="assets/images/icon/teammate.jpeg"
                          alt="teammate"
                          className="rounded-2"
                        />{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Box>
      </> */}
    </>
  );
};

export default Teammateshelp;
