import React, { useState, useEffect } from "react";
import "../componentCss/home.css";
import Piechart2 from "../../component/Dashboard/piechart";
import NestedPieChartComponent from "../../component/Dashboard/newPieChart";
import BasicPie from "../../component/Dashboard/newPieChart copy";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Table,
  Modal,
  Form,
  Pagination,
} from "react-bootstrap";
import Box from "@mui/material/Box";
import Sidebar from "./Sidebar";
import "./css/dashboard.css";
import Chart from "./PiChart/Chart";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { polygon, polygonMumbai, bscTestnet } from "viem/chains";
import { dashboardDetails, visualize } from "../utils/apiServices";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import { useWeb3Modal } from "@web3modal/react";
import {
  purchasePlan,
  depositInWallet,
  fetchChainId,
  getMaticAmountByUsdtAmount,
} from "../Web3/Connector";
import { useAccount } from "wagmi";
import config from "../../coreFiles/config";
import web3Config from "../Web3/web3Config";
import * as ACTIONTYPES from "../redux/actionTypes";
import { color } from "d3";
import FadeLoader from "react-spinners/FadeLoader";
import Loader from "react-js-loader";
import { TailSpin } from "react-loader-spinner";
const Dashboard = () => {


const [loader,setLoader]=useState(false)

  const [levelFirst, setLevelFirst] = useState([]);
  const [levelSecond, setLevelSecond] = useState([]);
  const [levelThird, setLevelThird] = useState([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  // let currentItems = [];
  // let totalPages = 0;
  let indexOfLastItem;
  let indexOfFirstItem;
  const dataSend1 = {
    categories: ["level"],

    series: [
      {
        name: "level",
        data: levelFirst,
      },
    ],
  };
  const dataSend2 = {
    categories: ["level"],

    series: [
      {
        name: "level",
        data: levelSecond,
      },
    ],
  };
  const dataSend3 = {
    categories: ["level1"],

    series: [
      {
        name: "level1",
        data: [levelThird[0], levelThird[1], levelThird[3]],
      },
    ],
  };
  const options = {
    chart: {
      title: "",
      height: 400,
      width: 300,
      animation: true,
    },
    theme: { chart: { backgroundColor: "transparent" } }, // Set theme to transparent
    legend: {
      showCheckbox: false,
      align: "bottom",
      visible: false,
    },
    series: {
      level: {
        radiusRange: {
          inner: "0%",
          outer: "100%",
        },
      },
      versions: {
        radiusRange: {
          inner: "55%",
          outer: "85%",
        },
        dataLabels: {
          visible: true,
          pieSeriesName: {
            visible: true,
          },
        },
      },
    },
    exportMenu: {
      visible: false,
    },
  };

  const { isConnected, address } = useAccount();
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const _useWeb3ModalProvider = Web3ModalProvider();

  const [show, setShow] = useState(false);
  const [show20, setShow20] = useState(false);

  const [showUpgradeWallet, setShowUpgradeWallet] = useState(false);
  const [showReEntryWallet, setShowReEntryWallet] = useState(false);
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtAmountErrorMessage, setUsdtAmountErrorMessage] = useState("");
  const [totalMatic, setTotalMatic] = useState("");

  const [spaceIdEnter, setSpaceIdEnter] = useState("");
  const [spaceIdEnterErrorMessage, setSpaceIdEnterErrorMessage] = useState("");
  const [spaceDonation, setspaceDonation] = useState([]);
  const [space, setSpace] = useState(1);

  const [vizualiseId, setVizualiseId] = useState("");
  const [vizualise, setVizualise] = useState("");

  const [vizualiseData, setVizualiseData] = useState([]);

  const [vizualiseId2, setVizualiseId2] = useState("");

  const [vizualiseData2, setVizualiseData2] = useState([]);

  const [dashboardDetail, setDashboardDetail] = useState([]);
  // -------------
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ---------

  const handleClose = () => {
    setShow(false);
    setShow20(false);

    setShowUpgradeWallet(false);
    setShowReEntryWallet(false);
    setUsdtAmountErrorMessage("");
    setUsdtAmount("");
    setTotalMatic("");
    setSpaceIdEnterErrorMessage("");
    setSpaceIdEnter("");
  };
  const handleShow = () => setShow(true);

  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const AuthToken = useSelector((state) => state.auth.AuthToken);

  useEffect(() => {
    fetchDashboardDetails();
  }, []);

  const fetchDashboardDetails = async () => {
    try {
      setLoader(true)
      let payload = loginDetails;
      payload["space"] = 1;
      let result = await dashboardDetails(payload,AuthToken);
      if (result.data.success) {
        setDashboardDetail(result.data.data);
        setVizualiseCallMethod(result.data.data[0].visualisation);

        dispatch({
          type: ACTIONTYPES.isVerified,
          payload: result.data.data[0].isVerified,
        });

        setspaceDonation(result.data.data[0].spaceDonation);
        
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const setVizualiseCallMethod = (datasssss) => {
    
    setVizualise(datasssss);
    setTotalPages(Math.ceil(datasssss.length / itemsPerPage));
    indexOfLastItem = currentPage * itemsPerPage;
    indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(datasssss.slice(indexOfFirstItem, indexOfLastItem));
    setLoader(false)
  };

  const setVizualiseCallMethod123 = (datasssss, pageNumber) => {
    setTotalPages(Math.ceil(datasssss.length / itemsPerPage));
    indexOfLastItem = pageNumber * itemsPerPage;
    indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(datasssss.slice(indexOfFirstItem, indexOfLastItem));
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setVizualiseCallMethod123(vizualise, pageNumber);
  };
  const activatePlan = async (e) => {
    e.preventDefault();
    try {
      setLoader(true)

      console.log("activatePlan");

      if (loginDetails.address != address) {
        toast.error("Please connect to registerred address");
      setLoader(false)

        return;
      }
      setLoader(true)
      let results = await purchasePlan(
        address,
        dashboardDetail[0].referredBy,
        _useWeb3ModalProvider
      );

      if (results.status) {
        toast.success(results.message);
      setLoader(false)

      } else {
        toast.error(results.message);
      setLoader(false)

      }
    } catch (error) {
      console.log(error);
      setLoader(false)

    }
  };

  const handleChange = async (value) => {
    setUsdtAmountErrorMessage("");
    let result = await getMaticAmountByUsdtAmount(_useWeb3ModalProvider, value);
    let totalMatics = 0;
    console.log(result);
    if (result.status) {
      totalMatics = result.value;
      console.log(result.value);
    }
    setUsdtAmount(value);
    setTotalMatic(totalMatics);
  };
  const handleChange1 = (value) => {
    setSpaceIdEnterErrorMessage("");
    setSpaceIdEnter(value);
  };
  const showUpgradeWalletModal = (e) => {
    e.preventDefault();
    setShow2(false);
    setShowUpgradeWallet(true);
  };
  const showReEntryWalletModal = (e) => {
    e.preventDefault();
    setShow2(false);

    setShowReEntryWallet(true);
  };

  const depositAmountValidation = () => {
    let error = "";

    if (usdtAmount <= 0) {
      error = "Amount must be greater then 0.";
    }
    if (error.length > 0) {
      setUsdtAmountErrorMessage(error);
      return false;
    }
    if (spaceIdEnter > dashboardDetail[0].space || spaceIdEnter < 1) {
      setSpaceIdEnterErrorMessage("Invalid space Id");
      return false;
    }

    return true;
  };

  const deposit = async (e, walletType) => {
    e.preventDefault();
    try {
      console.log("deposit");
      setLoader(true)

      let getChainId = await fetchChainId(_useWeb3ModalProvider);

      if (getChainId != web3Config.chainId) {
        toast.error(`Please switch to BSC mainnet with your connected wallet.`);
      setLoader(false)

        return;
      }

      let validation = depositAmountValidation();

      if (!validation) {
      setLoader(false)

        return;
      }

      let results = await depositInWallet(
        address,
        walletType,
        totalMatic,
        parseInt(spaceIdEnter),
        _useWeb3ModalProvider
      );

      if (results.status) {
        toast.success(results.message);
        handleClose();
      setLoader(false)

      } else {
        toast.error(results.message);
      setLoader(false)

      }
    } catch (error) {
      console.log(error);
      setLoader(false)

    }
  };

  const handleShowVisualize = async (e, id) => {
    e.preventDefault();
    try {
      setLoader(true)

      setVizualiseId(id);
      let payload = {
        id,
        space: space,
      };

      let response = await visualize(payload,AuthToken);
      if (response.data.success) {
        setShow(true);
        setVizualiseData(response.data.data);
      setLoader(false)

      } else {
        toast.error("No data found");
      setLoader(false)

      }
    } catch (error) {
      console.log(error);
      setLoader(false)

    }
  };
  const handleShowVisualize2 = async (e, id) => {
    e.preventDefault();
    try {
      setLoader(true)

      setVizualiseId2(id);
      let payload = {
        id,
        space: space,
      };

      let response = await visualize(payload);
      if (response.data.success) {
        setShow20(true);
        setLevelFirst(response.data.pieData[0].level);
        setLevelSecond(response.data.pieData[1].level);
        setLevelThird(response.data.pieData[2].level);
      setLoader(false)


      } else {
        toast.error("No data found");
        setLoader(false)
 

      }
    } catch (error) {
      console.log(error);
      setLoader(false)

    }
  };

  const changeTab = async (e, space) => {
    try {
      // console.log(loginDetails)
      setLoader(true)
      
      setSpace(space);
      let payload = loginDetails;
      payload["space"] = space;
      let result = await dashboardDetails(payload);
      // console.log(result.data);
      if (result.data.success) {
        setDashboardDetail(result.data.data);
        setspaceDonation(result.data.data[0].spaceDonation);
      setLoader(false)

      } else {
        toast.error(result.data.message);
      setLoader(false)

      }
    } catch (error) {}
  };

  return (
    <>
      <Toaster />

     { loader &&<div className="mainLoader">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#e1b028"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>}

      <Box sx={{ display: "flex" }} className="dashboard">
        <Sidebar />

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          {/* <Loader type="box-rotate-x" bgColor={color} color={color} size={100} /> */}
          {/* <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          /> */}
          <Row>
            <Col xxl={6} xl={5} lg={4} md={2} className=""></Col>
            <Col xxl={6} xl={7} lg={8} md={10} className="mb-4">
              <div className="Tablist w-100">
                <div className="d-sm-flex justify-content-between t-list mb-0 w-100 align-items-center">
                  <div className="pr-2 border-right">
                    <h6 className="mb-0">Status</h6>
                  </div>
                  {dashboardDetail.length > 0 &&
                  dashboardDetail[0].status == 1 ? (
                    <h5 className="text-primary mb-0 me-3">ACTIVE DONOR</h5>
                  ) : (
                    <h5 className="text-primary mb-0 me-3">INACTIVE DONOR</h5>
                  )}

                  <div className=" d-flex justify-content-between align-items-center">
                    {dashboardDetail.length > 0 &&
                    dashboardDetail[0].status == 1 ? (
                      <>
                        {/* <h5 className="text-primary mb-0 me-3">ACTIVE DONOR</h5> */}
                        <div>
                          <Button onClick={handleShow2}>Deposit</Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="me-3">
                          <Button
                            onClick={(e) => {
                              activatePlan(e);
                            }}
                          >
                            ACTIVATE
                          </Button>
                        </div>
                        <div className=" border-0">
                          <div className="">
                            <h6 className="text-blue mb-0">
                              {dashboardDetail.length > 0 &&
                                dashboardDetail[0].daysLeft}{" "}
                              Days left
                            </h6>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} className="mb-4">
              <Card className="cardBox p-4">
                <div className="card-list">
                  <ul className="">
                    {dashboardDetail.length > 0 &&
                      Array.from({ length: 5 }).map((_, index) =>
                        index + 1 <= dashboardDetail[0].space ? (
                          <li key={index} className="border-0">
                            {/* {console.log(dashboardDetail)} */}
                            <div className="boxlist">
                              <Button
                                variant="grey"
                                onClick={(e) => changeTab(e, index + 1)}
                                className={
                                  index + 1 == space
                                    ? "px-5 active"
                                    : "px-5 nonactive"
                                }
                                // onClick={handleShow}
                              >
                                Space {index + 1}
                              </Button>
                              <div>
                                {dashboardDetail[0].status == 0
                                  ? "Inactive"
                                  : "Active"}
                              </div>
                            </div>
                          </li>
                        ) : (
                          <li key={index} className="border-0">
                            <div className="boxlist">
                              <Button
                                variant="grey"
                                className="px-5 nonactive"
                                // onClick={handleShow}
                              >
                                Space {index + 1}
                              </Button>
                              <div>Inactive</div>
                            </div>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </Card>
            </Col>
            <Col lg={12} className="">
              <Row>
                <Col xl={3} lg={3} md={6} className="mb-4">
                  <h5 className="text-uppercase">Direct Members</h5>
                  <Card className="cardBox membercard p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="iconBox">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="red"
                        >
                          <g clip-path="url(#clip0_17_7591)">
                            <rect
                              width="40"
                              height="40"
                              rx="10"
                              fill="#E1B028"
                            />
                            <path
                              d="M20 18.3333C21.3185 18.3333 22.6075 17.9423 23.7038 17.2098C24.8001 16.4773 25.6546 15.4361 26.1592 14.2179C26.6638 12.9997 26.7958 11.6593 26.5386 10.3661C26.2813 9.07286 25.6464 7.88497 24.714 6.95262C23.7817 6.02027 22.5938 5.38534 21.3006 5.1281C20.0074 4.87087 18.667 5.00289 17.4488 5.50747C16.2306 6.01206 15.1894 6.86654 14.4569 7.96287C13.7243 9.0592 13.3333 10.3481 13.3333 11.6667C13.3333 13.4348 14.0357 15.1305 15.286 16.3807C16.5362 17.631 18.2319 18.3333 20 18.3333ZM20 8.33334C20.6593 8.33334 21.3037 8.52883 21.8519 8.8951C22.4001 9.26138 22.8273 9.78197 23.0796 10.3911C23.3319 11.0001 23.3979 11.6704 23.2693 12.317C23.1407 12.9636 22.8232 13.5575 22.357 14.0237C21.8908 14.4899 21.2969 14.8073 20.6503 14.936C20.0037 15.0646 19.3335 14.9986 18.7244 14.7463C18.1153 14.494 17.5947 14.0667 17.2284 13.5186C16.8622 12.9704 16.6667 12.3259 16.6667 11.6667C16.6667 10.7826 17.0179 9.93477 17.643 9.30965C18.2681 8.68453 19.1159 8.33334 20 8.33334Z"
                              fill="white"
                            />
                            <path
                              d="M20 21.6665C16.9058 21.6665 13.9383 22.8957 11.7504 25.0836C9.5625 27.2715 8.33334 30.239 8.33334 33.3332C8.33334 33.7752 8.50893 34.1991 8.82149 34.5117C9.13405 34.8242 9.55798 34.9998 10 34.9998C10.442 34.9998 10.866 34.8242 11.1785 34.5117C11.4911 34.1991 11.6667 33.7752 11.6667 33.3332C11.6667 31.123 12.5446 29.0034 14.1074 27.4406C15.6702 25.8778 17.7899 24.9998 20 24.9998C22.2101 24.9998 24.3298 25.8778 25.8926 27.4406C27.4554 29.0034 28.3333 31.123 28.3333 33.3332C28.3333 33.7752 28.5089 34.1991 28.8215 34.5117C29.1341 34.8242 29.558 34.9998 30 34.9998C30.442 34.9998 30.866 34.8242 31.1785 34.5117C31.4911 34.1991 31.6667 33.7752 31.6667 33.3332C31.6667 30.239 30.4375 27.2715 28.2496 25.0836C26.0617 22.8957 23.0942 21.6665 20 21.6665Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17_7591">
                              <rect
                                width="40"
                                height="40"
                                rx="10"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>

                      <div className="text-right">
                        <span>
                          {dashboardDetail.length > 0 && (
                            <img
                              src={`${
                                dashboardDetail[0].DIRECT_MEMBERS
                                  .directDifference >= 0
                                  ? "assets/images/dashboard/icon/green_chart.svg"
                                  : "assets/images/dashboard/icon/red_chart.svg"
                              }`}
                            />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].DIRECT_MEMBERS.directDifference}
                        </span>
                      </div>
                    </div>
                    <div className="text-center chart-wave-line">
                      {dashboardDetail.length > 0 &&
                      dashboardDetail[0].DIRECT_MEMBERS.directDifference >=
                        0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M1.00002 6.2396L6.89544 3.69934C9.57518 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#4BD709"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M1 6.2396L6.89542 3.69934C9.57516 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#FF0000"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center text-end">
                      <div className="">
                        {dashboardDetail.length > 0 && (
                          <h4>
                            {dashboardDetail[0].DIRECT_MEMBERS.directCount}
                          </h4>
                        )}
                        <a
                          href={`${config.baseUrl}spacenetwork`}
                          className="text-primary text-decoration-none"
                        >
                          View all
                        </a>
                      </div>
                      <div className="mt-3">
                        <span>
                          {dashboardDetail.length > 0 &&
                          dashboardDetail[0].DIRECT_MEMBERS
                            .directAfterPercentage >= 0 ? (
                            <img src="assets/images/dashboard/icon/upsidearrow.svg" />
                          ) : (
                            <img src="assets/images/dashboard/icon/downsidearrow.svg" />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            parseFloat(
                              dashboardDetail[0].DIRECT_MEMBERS
                                .directAfterPercentage
                            ).toFixed(2)}
                          %
                        </span>
                        <p className="mb-0 mt-2">This Month</p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xl={3} lg={3} md={6} className="mb-4">
                  <h5 className="text-uppercase">Matrix Members</h5>
                  <Card className="cardBox membercard p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4 text-end">
                      <div className="iconBox">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_17_7591)">
                            <rect
                              width="40"
                              height="40"
                              rx="10"
                              fill="#E1B028"
                            />
                            <path
                              d="M20 18.3333C21.3185 18.3333 22.6075 17.9423 23.7038 17.2098C24.8001 16.4773 25.6546 15.4361 26.1592 14.2179C26.6638 12.9997 26.7958 11.6593 26.5386 10.3661C26.2813 9.07286 25.6464 7.88497 24.714 6.95262C23.7817 6.02027 22.5938 5.38534 21.3006 5.1281C20.0074 4.87087 18.667 5.00289 17.4488 5.50747C16.2306 6.01206 15.1894 6.86654 14.4569 7.96287C13.7243 9.0592 13.3333 10.3481 13.3333 11.6667C13.3333 13.4348 14.0357 15.1305 15.286 16.3807C16.5362 17.631 18.2319 18.3333 20 18.3333ZM20 8.33334C20.6593 8.33334 21.3037 8.52883 21.8519 8.8951C22.4001 9.26138 22.8273 9.78197 23.0796 10.3911C23.3319 11.0001 23.3979 11.6704 23.2693 12.317C23.1407 12.9636 22.8232 13.5575 22.357 14.0237C21.8908 14.4899 21.2969 14.8073 20.6503 14.936C20.0037 15.0646 19.3335 14.9986 18.7244 14.7463C18.1153 14.494 17.5947 14.0667 17.2284 13.5186C16.8622 12.9704 16.6667 12.3259 16.6667 11.6667C16.6667 10.7826 17.0179 9.93477 17.643 9.30965C18.2681 8.68453 19.1159 8.33334 20 8.33334Z"
                              fill="white"
                            />
                            <path
                              d="M20 21.6665C16.9058 21.6665 13.9383 22.8957 11.7504 25.0836C9.5625 27.2715 8.33334 30.239 8.33334 33.3332C8.33334 33.7752 8.50893 34.1991 8.82149 34.5117C9.13405 34.8242 9.55798 34.9998 10 34.9998C10.442 34.9998 10.866 34.8242 11.1785 34.5117C11.4911 34.1991 11.6667 33.7752 11.6667 33.3332C11.6667 31.123 12.5446 29.0034 14.1074 27.4406C15.6702 25.8778 17.7899 24.9998 20 24.9998C22.2101 24.9998 24.3298 25.8778 25.8926 27.4406C27.4554 29.0034 28.3333 31.123 28.3333 33.3332C28.3333 33.7752 28.5089 34.1991 28.8215 34.5117C29.1341 34.8242 29.558 34.9998 30 34.9998C30.442 34.9998 30.866 34.8242 31.1785 34.5117C31.4911 34.1991 31.6667 33.7752 31.6667 33.3332C31.6667 30.239 30.4375 27.2715 28.2496 25.0836C26.0617 22.8957 23.0942 21.6665 20 21.6665Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17_7591">
                              <rect
                                width="40"
                                height="40"
                                rx="10"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>

                      <div>
                        <span>
                          {dashboardDetail.length > 0 && (
                            <img
                              src={`${
                                dashboardDetail[0].MATRIX_MEMBERS
                                  .matrixReferralChangePercentageDifference >= 0
                                  ? "assets/images/dashboard/icon/green_chart.svg"
                                  : "assets/images/dashboard/icon/red_chart.svg"
                              }`}
                            />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].MATRIX_MEMBERS
                              .matrixReferralChangePercentageDifference}
                        </span>
                      </div>
                    </div>
                    <div className="text-center chart-wave-line">
                      {dashboardDetail.length > 0 &&
                      dashboardDetail[0].MATRIX_MEMBERS
                        .matrixReferralChangePercentageDifference >= 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M1.00002 6.2396L6.89544 3.69934C9.57518 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#4BD709"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M1 6.2396L6.89542 3.69934C9.57516 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#FF0000"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center text-end">
                      <div className="">
                        {dashboardDetail.length > 0 && (
                          <h4>
                            {
                              dashboardDetail[0].MATRIX_MEMBERS
                                .matrixReferralCount
                            }
                          </h4>
                        )}
                      </div>
                      <div className="mt-3">
                        <span>
                          {dashboardDetail.length > 0 &&
                          dashboardDetail[0].MATRIX_MEMBERS
                            .matrixReferralChangePercentage >= 0 ? (
                            <img src="assets/images/dashboard/icon/upsidearrow.svg" />
                          ) : (
                            <img src="assets/images/dashboard/icon/downsidearrow.svg" />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].MATRIX_MEMBERS
                              .matrixReferralChangePercentage}
                          %
                        </span>
                        <p className="mb-0 mt-2">This Month</p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xl={3} lg={3} md={6} className="mb-4">
                  <h5 className="text-uppercase">Referral Donation</h5>
                  <Card className="cardBox membercard p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4 text-end">
                      <div className="iconBox">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_17_7591)">
                            <rect
                              width="40"
                              height="40"
                              rx="10"
                              fill="#E1B028"
                            />
                            <path
                              d="M20 18.3333C21.3185 18.3333 22.6075 17.9423 23.7038 17.2098C24.8001 16.4773 25.6546 15.4361 26.1592 14.2179C26.6638 12.9997 26.7958 11.6593 26.5386 10.3661C26.2813 9.07286 25.6464 7.88497 24.714 6.95262C23.7817 6.02027 22.5938 5.38534 21.3006 5.1281C20.0074 4.87087 18.667 5.00289 17.4488 5.50747C16.2306 6.01206 15.1894 6.86654 14.4569 7.96287C13.7243 9.0592 13.3333 10.3481 13.3333 11.6667C13.3333 13.4348 14.0357 15.1305 15.286 16.3807C16.5362 17.631 18.2319 18.3333 20 18.3333ZM20 8.33334C20.6593 8.33334 21.3037 8.52883 21.8519 8.8951C22.4001 9.26138 22.8273 9.78197 23.0796 10.3911C23.3319 11.0001 23.3979 11.6704 23.2693 12.317C23.1407 12.9636 22.8232 13.5575 22.357 14.0237C21.8908 14.4899 21.2969 14.8073 20.6503 14.936C20.0037 15.0646 19.3335 14.9986 18.7244 14.7463C18.1153 14.494 17.5947 14.0667 17.2284 13.5186C16.8622 12.9704 16.6667 12.3259 16.6667 11.6667C16.6667 10.7826 17.0179 9.93477 17.643 9.30965C18.2681 8.68453 19.1159 8.33334 20 8.33334Z"
                              fill="white"
                            />
                            <path
                              d="M20 21.6665C16.9058 21.6665 13.9383 22.8957 11.7504 25.0836C9.5625 27.2715 8.33334 30.239 8.33334 33.3332C8.33334 33.7752 8.50893 34.1991 8.82149 34.5117C9.13405 34.8242 9.55798 34.9998 10 34.9998C10.442 34.9998 10.866 34.8242 11.1785 34.5117C11.4911 34.1991 11.6667 33.7752 11.6667 33.3332C11.6667 31.123 12.5446 29.0034 14.1074 27.4406C15.6702 25.8778 17.7899 24.9998 20 24.9998C22.2101 24.9998 24.3298 25.8778 25.8926 27.4406C27.4554 29.0034 28.3333 31.123 28.3333 33.3332C28.3333 33.7752 28.5089 34.1991 28.8215 34.5117C29.1341 34.8242 29.558 34.9998 30 34.9998C30.442 34.9998 30.866 34.8242 31.1785 34.5117C31.4911 34.1991 31.6667 33.7752 31.6667 33.3332C31.6667 30.239 30.4375 27.2715 28.2496 25.0836C26.0617 22.8957 23.0942 21.6665 20 21.6665Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17_7591">
                              <rect
                                width="40"
                                height="40"
                                rx="10"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>

                      {/* <div>
                        <span>
                          {dashboardDetail.length > 0 && (
                            <img
                              src={`${
                                dashboardDetail[0].REFERRAL_DONATION
                                  .directReferralDifference >= 0
                                  ? "assets/images/dashboard/icon/green_chart.svg"
                                  : "assets/images/dashboard/icon/red_chart.svg"
                              }`}
                            />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].REFERRAL_DONATION
                              .directReferralDifference}
                        </span>
                      </div> */}
                    </div>
                    {/* <div className="text-center chart-wave-line">
                      {dashboardDetail.length > 0 &&
                      dashboardDetail[0].REFERRAL_DONATION
                        .directReferralDifference >= 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M1.00002 6.2396L6.89544 3.69934C9.57518 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#4BD709"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M1 6.2396L6.89542 3.69934C9.57516 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#FF0000"
                          />
                        </svg>
                      )}
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center text-end">
                      <div className="">
                        {dashboardDetail.length > 0 && (
                          <h4>
                            {
                              dashboardDetail[0].REFERRAL_DONATION
                                .directReferralAmount
                            }
                          </h4>
                        )}
                        <a
                          href={`${config.baseUrl}invitedonation`}
                          className="text-primary text-decoration-none"
                        >
                          View all
                        </a>
                      </div>
                      {/* <div className="mt-3">
                        <span>
                          {dashboardDetail.length > 0 &&
                          dashboardDetail[0].REFERRAL_DONATION
                            .directReferralAfterAmountpercentage >= 0 ? (
                            <img src="assets/images/dashboard/icon/upsidearrow.svg" />
                          ) : (
                            <img src="assets/images/dashboard/icon/downsidearrow.svg" />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].REFERRAL_DONATION
                              .directReferralAfterAmountpercentage}
                          %
                        </span>
                         <p className="mb-0 mt-2">This Month</p> 
                      </div> */}
                    </div>
                  </Card>
                </Col>
                <Col xl={3} lg={3} md={6} className="mb-4">
                  <h5 className="text-uppercase">Matrix Donation</h5>
                  <Card className="cardBox membercard p-4">
                    <div className="d-flex justify-content-between align-items-center mb-4 text-end">
                      <div className="iconBox">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_17_7591)">
                            <rect
                              width="40"
                              height="40"
                              rx="10"
                              fill="#E1B028"
                            />
                            <path
                              d="M20 18.3333C21.3185 18.3333 22.6075 17.9423 23.7038 17.2098C24.8001 16.4773 25.6546 15.4361 26.1592 14.2179C26.6638 12.9997 26.7958 11.6593 26.5386 10.3661C26.2813 9.07286 25.6464 7.88497 24.714 6.95262C23.7817 6.02027 22.5938 5.38534 21.3006 5.1281C20.0074 4.87087 18.667 5.00289 17.4488 5.50747C16.2306 6.01206 15.1894 6.86654 14.4569 7.96287C13.7243 9.0592 13.3333 10.3481 13.3333 11.6667C13.3333 13.4348 14.0357 15.1305 15.286 16.3807C16.5362 17.631 18.2319 18.3333 20 18.3333ZM20 8.33334C20.6593 8.33334 21.3037 8.52883 21.8519 8.8951C22.4001 9.26138 22.8273 9.78197 23.0796 10.3911C23.3319 11.0001 23.3979 11.6704 23.2693 12.317C23.1407 12.9636 22.8232 13.5575 22.357 14.0237C21.8908 14.4899 21.2969 14.8073 20.6503 14.936C20.0037 15.0646 19.3335 14.9986 18.7244 14.7463C18.1153 14.494 17.5947 14.0667 17.2284 13.5186C16.8622 12.9704 16.6667 12.3259 16.6667 11.6667C16.6667 10.7826 17.0179 9.93477 17.643 9.30965C18.2681 8.68453 19.1159 8.33334 20 8.33334Z"
                              fill="white"
                            />
                            <path
                              d="M20 21.6665C16.9058 21.6665 13.9383 22.8957 11.7504 25.0836C9.5625 27.2715 8.33334 30.239 8.33334 33.3332C8.33334 33.7752 8.50893 34.1991 8.82149 34.5117C9.13405 34.8242 9.55798 34.9998 10 34.9998C10.442 34.9998 10.866 34.8242 11.1785 34.5117C11.4911 34.1991 11.6667 33.7752 11.6667 33.3332C11.6667 31.123 12.5446 29.0034 14.1074 27.4406C15.6702 25.8778 17.7899 24.9998 20 24.9998C22.2101 24.9998 24.3298 25.8778 25.8926 27.4406C27.4554 29.0034 28.3333 31.123 28.3333 33.3332C28.3333 33.7752 28.5089 34.1991 28.8215 34.5117C29.1341 34.8242 29.558 34.9998 30 34.9998C30.442 34.9998 30.866 34.8242 31.1785 34.5117C31.4911 34.1991 31.6667 33.7752 31.6667 33.3332C31.6667 30.239 30.4375 27.2715 28.2496 25.0836C26.0617 22.8957 23.0942 21.6665 20 21.6665Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17_7591">
                              <rect
                                width="40"
                                height="40"
                                rx="10"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>

                      {/* <div>
                        <span>
                          {dashboardDetail.length > 0 && (
                            <img
                              src={`${
                                dashboardDetail[0].MATRIX_DONATION
                                  .matrixReferralAmountChangeDifference >= 0
                                  ? "assets/images/dashboard/icon/green_chart.svg"
                                  : "assets/images/dashboard/icon/red_chart.svg"
                              }`}
                            />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            parseInt(
                              dashboardDetail[0].MATRIX_DONATION
                                .matrixReferralAmountChangeDifference
                            )}
                        </span>
                      </div> */}
                    </div>
                    {/* <div className="text-center chart-wave-line">
                      {dashboardDetail.length > 0 &&
                      dashboardDetail[0].MATRIX_DONATION
                        .matrixReferralAmountChangeDifference >= 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M1.00002 6.2396L6.89544 3.69934C9.57518 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#4BD709"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="125"
                          height="27"
                          viewBox="0 0 125 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M1 6.2396L6.89542 3.69934C9.57516 2.60276 15.4706 -1.78358 21.366 3.69932C27.7974 9.73051 34.4967 19.3986 40.3922 23.2366C46.2876 26.5263 52.183 21.0434 58.0784 16.6571C63.9739 12.2708 69.8693 8.98105 76.3007 12.8191C82.1961 16.6571 88.0915 26.5263 93.9869 25.978C99.8824 25.4298 105.778 13.3674 111.673 8.43276C117.569 3.49815 118.105 7.33618 124 8.43276"
                            stroke="#FF0000"
                          />
                        </svg>
                      )}
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center text-end">
                      <div className="">
                        {dashboardDetail.length > 0 && (
                          <h4>
                            {
                              dashboardDetail[0].MATRIX_DONATION
                                .matrixReferralAmount
                            }
                          </h4>
                        )}
                        <a
                          href={`${config.baseUrl}invitedonation`}
                          className="text-primary text-decoration-none"
                        >
                          View all
                        </a>
                      </div>
                      {/* <div className="mt-3">
                        <span>
                          {dashboardDetail.length > 0 &&
                          dashboardDetail[0].MATRIX_DONATION
                            .matrixReferralAmountChangePercentage >= 0 ? (
                            <img src="assets/images/dashboard/icon/upsidearrow.svg" />
                          ) : (
                            <img src="assets/images/dashboard/icon/downsidearrow.svg" />
                          )}
                          &nbsp;
                          {dashboardDetail.length > 0 &&
                            dashboardDetail[0].MATRIX_DONATION
                              .matrixReferralAmountChangePercentage}
                          %
                        </span>
                         <p className="mb-0 mt-2">This Month</p>
                      </div> */}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>

            {/* <Col lg={12} className="mb-4">
              <h5 className="text-uppercase">Space 0{space}</h5>
              <Card className="cardBox overflow-hidden sameHeight">
                <div className="d-table">
                  <Table responsive striped hover className="text-center mb-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Types</th>
                        <th>Level 01 </th>
                        <th>Level 02</th>
                        <th>Level 03</th>
                        <th>Matrix</th>
                        <th>Deposit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardDetail.length > 0 &&
                        dashboardDetail[0].visualisation.map((item) => (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.type}</td>
                            <td>{item.level1}</td>
                            <td>{item.level2}</td>
                            <td>{item.level3}</td>
                            <td>
                              <Button
                                variant="outline-primary"
                                onClick={(e) => {
                                  handleShowVisualize2(e, item.id);
                                }}
                              >
                                Visualize
                              </Button>
                            </td>
                            <td>
                              <Button
                                variant="outline-primary"
                                onClick={(e) => {
                                  handleShowVisualize(e, item.id);
                                }}
                              >
                                Payment
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Card>
              <div className="mt-3">
                <Pagination className="justify-content-end">
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Ellipsis />

                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item active>{12}</Pagination.Item>
                  <Pagination.Item>{13}</Pagination.Item>
                  <Pagination.Item disabled>{14}</Pagination.Item>

                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div>
            </Col> */}
            <Col lg={12} className="mb-4">
              <h5 className="text-uppercase">Space 0{space}</h5>
              <Card className="cardBox overflow-hidden sameHeight">
                <div className="d-table">
                  <Table responsive striped hover className="text-center mb-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Types</th>
                        <th>Level 01</th>
                        <th>Level 02</th>
                        <th>Level 03</th>
                        <th>Matrix</th>
                        <th>Deposit</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      {currentItems.length > 0 &&
                        currentItems.map((item,index) => (
                          <tr key={item.id}>
                            <td>{index+1}</td>
                            <td>{item.type}</td>
                            <td>{item.level1}</td>
                            <td>{item.level2}</td>
                            <td>{item.level3}</td>
                            <td>
                              <Button
                                variant="outline-primary"
                                onClick={(e) => {
                                  handleShowVisualize2(e, item.id);
                                }}
                              >
                                Visualize
                              </Button>
                            </td>
                            <td>
                              <Button
                                variant="outline-primary"
                                onClick={(e) => {
                                  handleShowVisualize(e, item.id);
                                }}
                              >
                                Payment
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Card>
              <div className="mt-3">
                <Pagination className="justify-content-end">
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  />
                  {/* {[...Array(totalPages)].map((_, idx) => (
                    <Pagination.Item
                      key={idx + 1}
                      active={idx + 1 === currentPage}
                      onClick={() => handlePageChange(idx + 1)}
                    >
                      {idx + 1}
                    </Pagination.Item>
                  ))} */}
                  <Pagination.Item
                    onClick={() => handlePageChange(currentPage)}
                  >
                    {currentPage}
                  </Pagination.Item>
                  <Pagination.Item>...</Pagination.Item>
                  <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                  </Pagination.Item>

                  <Pagination.Next
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages
                      )
                    }
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(totalPages)}
                  />
                </Pagination>
              </div>
            </Col>
            <Col lg={12} className="mb-4">
              <Row className=" mb-3">
                <Col lg={8}>
                  <Row className="align-items-center ">
                    <Col className="">
                      <h5 className="text-uppercase mb-0">Wallet </h5>
                      <p>
                        (ALL TRANSACTIONS ARE SUBJECTED TO 2% GAS FEE BY SPACE
                        LOOP)
                      </p>
                    </Col>
                    <Col className="text-end">
                      <div>
                        {dashboardDetail.length > 0 &&
                          dashboardDetail[0].status == 1 && (
                            <>
                              <Button onClick={handleShow2}>Deposit</Button>
                            </>
                          )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}></Col>
              </Row>
              <Card className="cardBox overflow-hidden">
                <div className="d-table table-responsive">
                  <Table hover className="text-center mb-0">
                    <thead>
                      <tr>
                        <th colSpan={`3`} className="border border-dark">
                          Personal Escrow Wallet
                        </th>
                        <th colSpan={`2`} className="border border-dark">
                          Personal Private Wallets
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-right border-dark">
                          P.I.F. Wallet
                        </td>
                        <td className="border-right border-dark">
                          Upgrade wallet
                        </td>
                        <td className="border-right border-dark">
                          Re-entry wallet
                        </td>
                        <td className="border-right border-dark">
                          Matic wallet
                        </td>
                        <td className="border-right border-dark">
                          Withdrawn to matic wallet
                        </td>
                      </tr>

                      {dashboardDetail.length > 0 && (
                        <tr>
                          <td className="border border-dark">
                            <h4 className="py-5">
                              ${dashboardDetail[0].balance.pifBalance}
                            </h4>
                          </td>
                          <td className="border border-dark">
                            <h4 className="py-5">
                              ${dashboardDetail[0].balance.upgradeBalance}
                            </h4>
                          </td>
                          <td className="border border-dark">
                            <h4 className="py-5">
                              ${dashboardDetail[0].balance.reEntryBalance}
                            </h4>
                          </td>
                          <td className="border border-dark">
                            <h4 className="py-5">
                              ${dashboardDetail[0].balance.refMaticBalance}
                            </h4>
                          </td>
                          <td className="border border-dark">
                            <h4 className="py-5">
                              ${dashboardDetail[0].balance.withMaticBalance}
                            </h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        </Box>
        {/* ---------Space 01---------- */}
        {/* <Modal show={show} onHide={handleClose} centered className="d-modal">
          <Modal.Header closeButton className="py-2">
            <Modal.Title className="fs-5">
              Space 01 payment : <span className="text-primary">ID 01</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mb-0">
            <div className="table-responsive">
              <Table bordered hover className="mb-0 text-center">
                <thead>
                  <tr>
                    <th>level</th>
                    <th>Wallet</th>
                    <th>Donation</th>
                    <th>Received</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>5</td>
                    <td>USD 5</td>
                    <td>USD 25</td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>25</td>
                    <td>USD 20</td>
                    <td>USD 500</td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>--</td>
                    <td>USD 5</td>
                    <td>--</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-block py-1">
            <Row className="align-items-center">
              <Col lg={6} className="text-center">
                total matrix donation
              </Col>
              <Col lg={6} className="text-right">
                USD 525
              </Col>
            </Row>
          </Modal.Footer>
        </Modal> */}
        {/* ----------payment-------- */}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="d-modal"
          size="lg"
        >
          <Modal.Header closeButton className="py-2">
            <Modal.Title className="fs-5">
              Payment <span className="text-primary">ID {vizualiseId}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mb-0 bg-white">
            <div className="text-center piechart mb-3">
              {/* <img src="assets/images/dashboard/piechart.svg" /> */}
              {/* <Chart /> */}
            </div>

            <Table responsive bordered hover className="mb-0 text-center">
              <thead>
                <tr>
                  <th>level</th>
                  <th>Wallet</th>
                  <th>Donation</th>
                  <th>Received</th>
                </tr>
              </thead>
              <tbody>
                {vizualiseData.length > 0 &&
                  vizualiseData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.Wallet}</td>
                      <td>USD {item.Donation}</td>
                      <td>USD {item.Recieved}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        {/* ----------Visualize-------- */}
        <Modal
          show={show20}
          onHide={handleClose}
          centered
          className="d-modal"
          size="xl"
        >
          <Modal.Header closeButton className="py-2">
            <Modal.Title className="fs-5">
              Visualize <span className="text-primary">ID {vizualiseId2}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mb-0 bg-white">
            <div className="text-center piechart mb-3">
              {/* <img src="assets/images/dashboard/piechart.svg" /> */}
              {/* <Chart /> */}
            </div>
            <Row>
              <Col lg={4} className="mb-3">
                <p className="text-center fw-bold fs-5">Level 1</p>
                {levelFirst.length > 0 ? (
                  <BasicPie data={levelFirst} />
                ) : (
                  <p className="d-flex justify-content-center align-items-center h-75 fs-5 fw-bold">
                    No data
                  </p>
                )}
              </Col>
              <Col lg={4} className="mb-3">
                <p className="text-center fw-bold fs-5">Level 2</p>
                {levelSecond.length > 0 ? (
                  <BasicPie data={levelSecond} />
                ) : (
                  <p className="d-flex justify-content-center align-items-center h-75 fs-5 fw-bold">
                    No data
                  </p>
                )}
              </Col>
              <Col lg={4} className="mb-3">
                <p className="text-center fw-bold fs-5">Level 3</p>
                {levelThird.length > 0 ? (
                  <BasicPie data={levelThird} />
                ) : (
                  <p className="d-flex justify-content-center align-items-center h-75 fs-5 fw-bold">
                    No data
                  </p>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={showUpgradeWallet} onHide={handleClose} centered>
          <Modal.Header
            className="border-0 border-bottom border-dark"
            closeButton
          >
            <Modal.Title>
              <h5 className="text-center mb-0" style={{ color: "white" }}>
                Deposit in upgrade wallet
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-5">
            <div className="">
              <div className="px-4">
                <p style={{ color: "white" }} className="mb-2">
                  {" "}
                  Please enter USDT amount for deposit
                </p>
                <Form.Control
                  type="number"
                  style={
                    usdtAmountErrorMessage != "" ? { borderColor: "red" } : {}
                  }
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                  value={usdtAmount}
                ></Form.Control>
              </div>
              {usdtAmountErrorMessage != "" && (
                <span style={{ color: "red" }}>{usdtAmountErrorMessage}</span>
              )}
              <div className="px-4">
                <p style={{ color: "white" }}>{totalMatic} ~ MATIC</p>
              </div>

              <div className="px-4">
                <p style={{ color: "white" }} className="mb-2">
                  {" "}
                  Please enter Space Id
                </p>
                <Form.Control
                  type="number"
                  style={
                    spaceIdEnterErrorMessage != "" ? { borderColor: "red" } : {}
                  }
                  onChange={(e) => {
                    handleChange1(e.target.value);
                  }}
                  value={spaceIdEnter}
                ></Form.Control>
              </div>
              {spaceIdEnterErrorMessage != "" && (
                <span style={{ color: "red" }}>{spaceIdEnterErrorMessage}</span>
              )}
            </div>

            <div className="text-center">
              <Button
                variant="primary"
                className="my-3 px-4"
                onClick={(e) => {
                  deposit(e, 0);
                }}
              >
                Deposit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showReEntryWallet} onHide={handleClose} centered>
          <Modal.Header
            className="border-0 border-bottom border-dark"
            closeButton
          >
            <Modal.Title>
              <div className="text-center">
                <h5 style={{ color: "white" }} className="mb-0">
                  Deposit in re entry wallet
                </h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-5">
            <div className="">
              <div className="px-4">
                <p style={{ color: "white" }} className="mb-2">
                  Please enter USDT amount for deposit
                </p>
                <Form.Control
                  type="number"
                  style={
                    usdtAmountErrorMessage != "" ? { borderColor: "red" } : {}
                  }
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                  value={usdtAmount}
                ></Form.Control>
              </div>
              {usdtAmountErrorMessage != "" && (
                <span style={{ color: "red" }}>{usdtAmountErrorMessage}</span>
              )}
              <div className="px-4">
                <p style={{ color: "white" }}>{totalMatic} ~ MATIC</p>
              </div>
              <div className="px-4">
                <p style={{ color: "white" }} className="mb-2">
                  {" "}
                  Please enter Space Id
                </p>
                <Form.Control
                  type="number"
                  style={
                    spaceIdEnterErrorMessage != "" ? { borderColor: "red" } : {}
                  }
                  onChange={(e) => {
                    handleChange1(e.target.value);
                  }}
                  value={spaceIdEnter}
                ></Form.Control>
              </div>
              {spaceIdEnterErrorMessage != "" && (
                <span style={{ color: "red" }}>{spaceIdEnterErrorMessage}</span>
              )}
            </div>

            <div className="text-center">
              <Button
                variant="primary"
                className="my-3 px-4"
                onClick={(e) => {
                  deposit(e, 1);
                }}
              >
                Deposit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* --------------- */}

        <Modal
          show={show2}
          onHide={handleClose2}
          centered
          className="d-modal"
          size=""
        >
          <Modal.Header closeButton className="py-2">
            <Modal.Title className="fs-5">Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 mb-0 bg-white">
            <div className="text-center piechart mb-3"></div>
            <Container>
              <Row className="justify-content-center">
                <Col lg={10} md={10} sm={10}>
                  <div>
                    {dashboardDetail.length > 0 &&
                    dashboardDetail[0].status == 1 ? (
                      <>
                        <div className="border p-2 mb-3 rounded-5">
                          <Row className="align-items-center">
                            <Col lg={8} md={8} sm={8} xs={8} className="">
                              <div>
                                <h6 className="mb-0 pl-1">
                                  Deposit in upgrade wallet
                                </h6>
                              </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4} className="">
                              <Button
                                onClick={(e) => {
                                  showUpgradeWalletModal(e);
                                }}
                                className="rounded-5 px-4"
                              >
                                Deposit
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <div className="border p-2 mb-3 rounded-5">
                          <Row className="align-items-center">
                            <Col lg={8} md={8} sm={8} xs={8}>
                              <div>
                                <h6 className="mb-0 pl-1">
                                  Deposit in re-entry wallet
                                </h6>
                              </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                              <Button
                                onClick={(e) => {
                                  showReEntryWalletModal(e);
                                }}
                                className="rounded-5 px-4"
                              >
                                Deposit
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </Box>
    </>
  );
};

export default Dashboard;
